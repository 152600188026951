/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useMemo, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { STATIC_URL, API_URL, custom_axios, custom_axios_post } from "../Common/Common";
import {
  DataGrid,
  // GridToolbar,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  jaJP,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ShowAnpiCreate from "../Common/ShowAnpiCreate";
import ShowCommon from "../Common/ShowCommon";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import Badge from "@mui/material/Badge";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import Search from "@mui/icons-material/Search";
import ShowAnpiVote from "./ShowAnpiVote";
import Snackbar from "@mui/material/Snackbar";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import ShowAnpiUserView from "../Common/ShowAnpiUserView";
import ShowAnpiChart from "../Common/ShowAnpiChart";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TablePagination from "@mui/material/TablePagination";
// import ExcelJS from "exceljs";
// import encoding from "encoding-japanese";
import ShowAnpiFilterInput from "../Common/ShowAnpiFilterInput";
// import PageviewOutlinedIcon from "@mui/icons-material/PageviewOutlined";
// import PageviewRoundedIcon from "@mui/icons-material/PageviewRounded";
// import Tooltip from "@mui/material/Tooltip";
// import Close from "@mui/icons-material/Close";
// import GetAppIcon from "@mui/icons-material/GetApp";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
const headCells = [
  { id: "hpname", label: "病院名" },
  // { id: "unitname", label: "部署名" },
  { id: "all", label: "合計" },
  { id: "vote", label: "済" },
  { id: "novote", label: "未" },
];

const headCells_unit = [
  { id: "hpname", label: "病院名" },
  { id: "unitname", label: "部署名" },
  { id: "uall", label: "合計" },
  { id: "uvote", label: "済" },
  { id: "unovote", label: "未" },
];

const headCells_member_original = [
  { id: "fix", label: "修正" },
  { id: "honbuid", label: "ID" },
  { id: "unitname", label: "部署名" },
  { id: "name", label: "名前" },

  { id: "vote", label: "済" },
];
const StyledDataGrid = styled("div")`
  height: 90%;
  weight: 100%;
`;
const StyledIconButton = styled(IconButton)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;
const StyledDivAccordion = styled("div")`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledTypography = styled(Typography)`
  margin: 15px;
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledCntLink = styled(Link)`
  font-size: medium;
`;

const StyledTable = styled(Table)`
  min-width: 750;
`;
const StyledTableBody = styled(TableBody)`
  overflow-x: visible;
`;

const StyledSpan = styled("span")`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledAnpiWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};
  cursor: pointer;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  /* width: 295px; */
  //height: 38px;
  position: relative;
  z-index: 0;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    & .chat-name {
      font-weight: 700;
    }
    & .chat-image {
      border: 1px solid white;
    }
  }
  & .chat-name {
    //color: ${(props) => (props.bigfont ? props.theme.palette.primary.contrastText : "black")};
    font-weight: ${(props) => (props.bigfont ? "700" : "normal")};
  }
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}
function ShowAnpiList({ open, handleClose, createOk }) {
  const history = useHistory();
  const { state } = useContext(Store);
  const [selectedAnpiid, setSelectedAnpiid] = useState(null);
  const [selectedIsTemp, setSelectedIsTemp] = useState(false);
  const [localList, setLocalList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  // const [membersColumns, setMembersColumns] = useState([]);
  // const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackopen, setSnackOpen] = useState(false);
  const [snackpushopen, setSnackPushOpen] = useState(false);
  const [value, setValue] = React.useState(0);

  /* サマリー */
  const [sumdata, setSumData] = useState([]);
  const [alldata, setAllData] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [originalmembersList, setOriginalMembersList] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sumPage, setSumPage] = useState(0);
  const [sumRowsPerPage, setSumRowsPerPage] = useState(10);
  // eslint-disable-next-line no-unused-vars
  const [headCells_member, setHeadCells_member] = useState([]);
  const [voteList, setVoteList] = useState([]);
  const [menuItemList, setMenuItemVoteList] = useState([]);

  function descendingComparator(a, b, orderBy) {
    if (orderBy == "honbuid" || orderBy == "uall" || orderBy == "uvote" || orderBy == "unovote") {
      if (Number(b[orderBy]) < Number(a[orderBy])) {
        return -1;
      }
      if (Number(b[orderBy]) > Number(a[orderBy])) {
        return 1;
      }
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }

    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function EnhancedTableHead_unit(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler_unit = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headCells_unit.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding="normal"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler_unit(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangeSumPage = (event, newPage) => {
    setSumPage(newPage);
  };

  const handleChangeSumRowsPerPage = (event) => {
    setSumRowsPerPage(parseInt(event.target.value, 10));
    setSumPage(0);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const mainproc = async () => {
    let honbuid = localStorage.getItem("honbuid");
    if (open) {
      setMembersList([]);
      setSelectedAnpiid(null);

      setSumData([]);

      const result = await custom_axios(API_URL + `/GetAnpiList?honbuid=${honbuid}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        setLocalList(result.data.anpilist);
      }
    }
  };

  useEffect(() => {
    mainproc();
  }, [open]);

  /* 左メニューをクリックしたときの動作 */
  const handleClick = async (q, t) => {
    setSelectedIsTemp(t == "1" ? true : false);
    setSelectedAnpiid(q);
  };
  const voteproc = async (seqlist, memo) => {
    const isLargeNumber = (element) => element.honbuid == openVoteObj.honbuid;
    var inin = membersList.findIndex(isLargeNumber);
    const clonedData = [...membersList];
    seqlist.forEach((e) => {
      if (e.no == "0") {
        clonedData[inin]["vote" + e.seq] = e.value == "" ? "-" : e.value;
      } else {
        let ar = menuItemList.filter((f) => String(f.seq) === e.seq && String(f.no) === e.no);
        clonedData[inin]["vote" + e.seq] = ar[0]["text"];
      }
    });
    clonedData[inin]["memo"] = memo;
    clonedData[inin]["vote"] = "済";
    setMembersList(clonedData);
  };

  const subproc = async () => {
    setLoading(true);
    let honbuid = localStorage.getItem("honbuid");
    /* 1安否確認の画面更新 */
    var apistr = API_URL + `/GetAnpi?honbuid=${honbuid}&anpiid=${selectedAnpiid}`;
    const result2 = await custom_axios(apistr);
    const status2 = result2.status;
    if (status2 === 401) {
      history.replace("/loginError");
    }
    if (status2 === 200) {
      //個人別データ一覧(CloneData2)を作成（コード→名称に変換してる）
      const CloneData2 = [...result2.data.data];
      for (let g = 0; g < result2.data.data.length; g++) {
        for (let h = 5; h < result2.data.columns.length; h++) {
          let vote_key = "vote" + (h - 4).toString();
          if (result2.data.columns[h]["field"] === vote_key && result2.data.columns[h]["type"] === "radio") {
            if (result2.data.data[g][vote_key] !== "-") {
              let ar = result2.data.menuitems.filter(
                (f) => String(f.seq) === (h - 4).toString() && String(f.no) === result2.data.data[g][vote_key]
              );
              CloneData2[g][vote_key] = ar[0]["text"];
            }
          }
        }
      }

      //ヘッダー(cells)と、検索メニューのドロップダウン(votecollection)作成
      const votecollection = [];
      const cells = [...headCells_member_original];
      for (let j = 0; j < result2.data.columns.length; j++) {
        if (result2.data.columns[j]["type"] === "radio") {
          //if (result2.data.columns[j]["type"] === "radio" || result2.data.columns[j]["type"] === "text") {
          cells.push({ id: result2.data.columns[j]["field"], label: result2.data.columns[j]["title"] });
          var votesuffix = result2.data.columns[j]["field"].replace("vote", "");
          const vote_cell = {
            seq: votesuffix,
            title: result2.data.columns[j]["title"],
            field: result2.data.columns[j]["field"],
          };
          vote_cell["list"] = [];
          result2.data.menuitems
            .filter((f) => f.seq === votesuffix)
            .forEach((e) => {
              vote_cell["list"].push({ text: e.text, no: e.no - 1, checked: "true" });
            });
          vote_cell["list"].push({ text: "-", no: vote_cell["list"].length, checked: "true" });
          votecollection.push(vote_cell);
        }
        if (result2.data.columns[j]["type"] === "text") {
          cells.push({ id: result2.data.columns[j]["field"], label: result2.data.columns[j]["title"] });
        }
      }
      cells.push({ id: "memo", label: "メモ" });
      cells.push({ id: "inmmdd", label: "入力時刻" });
      cells.push({ id: "upmmdd", label: "修正時刻" });
      votecollection.unshift({
        seq: "0",
        title: "済",
        field: "vote",
        list: [
          { text: "済", no: "0", checked: "true" },
          { text: "-", no: "1", checked: "true" },
        ],
      });

      let unitcollection = {
        seq: "-1",
        title: "部署",
        field: "unitname",
        list: [],
      };
      for (let k = 0; k < result2.data.unitdata.length; k++) {
        unitcollection["list"].push({
          text: result2.data.unitdata[k]["unitname"],
          no: result2.data.unitdata[k]["unitcode"],
          checked: "true",
        });
      }
      votecollection.unshift(unitcollection);

      const xcolumns = cells.map((v) => {
        if (["vote"].includes(v.id)) {
          return {
            field: v.id,
            headerName: v.label,
            width: 50,
          };
        }
        if (v.id == "honbuid") {
          return {
            field: v.id,
            headerName: v.label,
          };
        }
        if (v.id == "fix") {
          return {
            field: v.id,
            headerName: v.label,
            width: 70,
            renderCell: (params) => {
              return (
                <Button
                  onClick={() =>
                    setOpenVoteObj({
                      honbuid: params.row.honbuid,
                      isopen: true,
                    })
                  }
                >
                  修正
                </Button>
              );
            },
          };
        }
        if (v.id == "name") {
          return {
            field: v.id,
            headerName: v.label,
            width: 200,
            valueGetter: (params) => {
              return params.row.name;
            },
            renderCell: (params) => {
              return (
                <div className="member-content">
                  <img
                    src={
                      STATIC_URL +
                      `/downloadAvatar?honbuid=${params.row.honbuid}&fileno=${params.row.fileno}&size=small`
                    }
                    width="30"
                    height="30"
                    alt=""
                    className="member-image"
                  />
                  {params.row.name}
                </div>
              );
            },
          };
        }
        if (["inmmdd"].includes(v.id)) {
          return {
            field: v.id,
            headerName: v.label,
            width: 100,
          };
        }
        if (["upmmdd"].includes(v.id)) {
          return {
            field: v.id,
            headerName: v.label,
            width: 100,
          };
        }

        return { field: v.id, headerName: v.label, width: 150 };
      });
      const xdatas = CloneData2.map((v) => {
        return { ...v, fix: "", id: v.honbuid };
      });

      // console.log("カラム", xcolumns);
      // console.log("データ", xdatas);

      setHeadCells_member(xcolumns);
      setMembersList(xdatas);
      setOriginalMembersList(CloneData2);
      setVoteList(votecollection);
      setMenuItemVoteList(result2.data.menuitems);
      setSumData(result2.data.sumdata);
      setAllData(result2.data.alldata);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedAnpiid && selectedIsTemp == false) {
      subproc();
    }
  }, [state.addVoteCnt, selectedAnpiid]);

  // const FilterSearch = () => {
  //   setOpenFilterInput(!openFilterInput);
  // };
  const handleFilterInputClose = () => {
    setOpenFilterInput(false);
  };
  const [openFilterInput, setOpenFilterInput] = useState(false);

  // const [inputfilters, setInputFilters] = useState("");

  const searchFilterProc = async (inputfilters) => {
    setVoteList(inputfilters);
    const filtercollection = [];
    for (let z = 0; z < inputfilters.length; z++) {
      const filterKeys = inputfilters[z]["list"]
        .map((value) => {
          if (value.checked === "true") return value.text;
        })
        .filter((e) => e);
      const filter_title = inputfilters[z]["field"];
      if (z === 0) {
        filtercollection.push(
          originalmembersList
            .map((e) => e)
            .filter(function (value) {
              return filterKeys.some((x) => value[filter_title] === x);
            })
        );
      } else {
        filtercollection.push(
          filtercollection[z - 1]
            .map((e) => e)
            .filter(function (value) {
              return filterKeys.some((x) => value[filter_title] === x);
            })
        );
      }
    }
    setMembersList(filtercollection[filtercollection.length - 1]);

    handleFilterInputClose();
  };

  const [openAnpiCreate, setOpenAnpiCreate] = useState(false);
  const handleAnpiCreateClose = () => {
    setOpenAnpiCreate(false);
  };

  const [openPushObj, setOpenPushObj] = useState({ honbuid: "", isopen: false });
  const handlePushClose = () => {
    setOpenPushObj({ ...openPushObj, isopen: false });
  };

  const handleAnpiVoteClose = () => {
    setOpenVoteObj({ ...openVoteObj, isopen: false });
  };
  const [openAnpiId, setOpenAnpiId] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const settingClick = (event, e) => {
    setOpenAnpiId(e);
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const settingClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openEndModal, setOpenEndModal] = useState(false);
  const [openAnpiCopy, setOpenAnpiCopy] = useState(false);
  const handleAnpiCopyClose = () => {
    setOpenAnpiCopy(false);
  };
  const delAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/delAnpi?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenModal(false);
    }
  };
  const endAnpi = async (q) => {
    const result = await custom_axios(API_URL + `/endAnpi?anpiid=${q}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      //setSelectedAnpiid(null);
      mainproc();
      setOpenEndModal(false);
    }
  };
  const [expanded, setExpanded] = React.useState(true);
  const [endexpanded, setEndExpanded] = React.useState(true);
  const [editexpanded, setEditExpanded] = React.useState(true);
  const [tempexpanded, setTempExpanded] = React.useState(true);
  const [quakeexpanded, setQuakeExpanded] = React.useState(true);
  const [openVoteObj, setOpenVoteObj] = React.useState({ honbuid: 0, isopen: false });
  const [openViewObj, setOpenViewObj] = React.useState({
    kbn: "",
    anpiid: 0,
    hpcode: "",
    unitcode: "",
    isopen: false,
    vote: "",
    seq: "",
    no: "",
  });
  const [openChartObj, setOpenChartObj] = React.useState({
    kbn: "",
    hpcode: "",
    unitcode: "",
    anpiid: "",
    isopen: false,
  });
  const handleAnpiChartClose = () => {
    setOpenChartObj({ ...openChartObj, isopen: false });
  };
  const handleAnpiUserViewClose = () => {
    setOpenViewObj({ ...openViewObj, isopen: false });
  };
  const MemoAnpiChart = useMemo(() => {
    return <ShowAnpiChart openChartObj={openChartObj} handleClose={handleAnpiChartClose} handleRefresh={subproc} />;
  }, [openChartObj.isopen]);
  const MemoAnpiCreate = useMemo(() => {
    return (
      <ShowAnpiCreate
        open={openAnpiCreate}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCreateClose}
        afterProc={mainproc}
        isCopy={false}
      />
    );
  }, [openAnpiCreate, openAnpiId]);

  const MemoAnpiCopy = useMemo(() => {
    return (
      <ShowAnpiCreate
        open={openAnpiCopy}
        openAnpiId={openAnpiId}
        handleClose={handleAnpiCopyClose}
        afterProc={mainproc}
        isCopy={true}
      />
    );
  }, [openAnpiCopy, openAnpiId]);

  const MemoAnpiUserView = useMemo(() => {
    return <ShowAnpiUserView openViewObj={openViewObj} handleClose={handleAnpiUserViewClose} />;
  }, [openViewObj]);
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openVoteObj.isopen}
        honbuid={openVoteObj.honbuid}
        openAnpiId={selectedAnpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={voteproc}
        roomid={null}
        isEnabled={true}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openVoteObj.isopen, openVoteObj.honbuid, selectedAnpiid]);

  const MemoFilterInput = useMemo(() => {
    return (
      <ShowAnpiFilterInput
        open={openFilterInput}
        handleClose={handleFilterInputClose}
        callBackProc={searchFilterProc}
        VoteLists={voteList}
        // setVoteLists={setVoteList}
      ></ShowAnpiFilterInput>
    );
  }, [openFilterInput, voteList]);

  const handlePush = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      anpiid: selectedAnpiid,
      honbuid: honbuid,
      roomid: openPushObj.honbuid,
    };

    const result = await custom_axios_post(API_URL + "/pushAnpiMember", body);

    if (result.status === 401) {
      history.replace("/loginError");
    }
    handlePushClose();
    setSnackPushOpen(true);
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} fullWidth maxWidth={"xl"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">安否確認一覧</div>
          <div className="question-title-actions">
            {createOk && (
              <StyledButton
                onClick={() => {
                  setOpenAnpiId(null);
                  setOpenAnpiCreate(true);
                }}
                variant="outlined"
              >
                安否確認作成
              </StyledButton>
            )}
            <div style={{ width: "20px" }}></div>
            <StyledButton
              onClick={() => {
                subproc();
              }}
              variant="outlined"
            >
              画面更新
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        {MemoAnpiCreate}
        {MemoAnpiCopy}
        {MemoAnpiVote}
        {MemoAnpiUserView}
        {MemoAnpiChart}
        {MemoFilterInput}
        <div className="question-dialog-wraper">
          <div className="anpi-member-list">
            <div>
              <StyledDivAccordion onClick={() => setExpanded(!expanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  実施中
                </div>
                <StyledIconButton size={"small"}>{expanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: expanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp != "1" && f.isend != "1" && f.istemplate == "0")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setEndExpanded(!endexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  終了
                </div>
                <StyledIconButton size={"small"}>{endexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: endexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp != "1" && f.isend == "1" && f.istemplate == "0")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setEditExpanded(!editexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  一時保存中
                </div>
                <StyledIconButton size={"small"}>{editexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: editexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp == "1" && f.isrenkei == "0")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setQuakeExpanded(!quakeexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  気象庁連携
                </div>
                <StyledIconButton size={"small"}>{quakeexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: quakeexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemp == "1" && f.isrenkei == "1")
                    .sort(function (a, b) {
                      if (a.timestring > b.timestring) {
                        return -1;
                      }
                      if (a.timestring < b.timestring) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              {e.isrenkei == "1" && (
                                <div>
                                  <StyledChip
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    label="気象庁連携"
                                  ></StyledChip>
                                </div>
                              )}
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <div>
              <StyledDivAccordion onClick={() => setTempExpanded(!tempexpanded)}>
                <div className="ac-group" style={{ marginLeft: "6px" }}>
                  テンプレート
                </div>
                <StyledIconButton size={"small"}>{tempexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
              </StyledDivAccordion>
              <div style={{ display: tempexpanded ? "" : "none" }}>
                {localList &&
                  localList
                    .filter((f) => f.istemplate == "1")
                    .sort(function (a, b) {
                      if (a.anpiid > b.anpiid) {
                        return -1;
                      }
                      if (a.anpiid < b.anpiid) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((e) => {
                      return (
                        <StyledAnpiWrapper
                          key={e.anpiid}
                          bigfont={selectedAnpiid === e.anpiid}
                          onClick={() => {
                            //handleClick(e.anpiid, e.istemp);
                          }}
                        >
                          <div className="anpi-member-content question-padding-wrap">
                            <div className="qustion-title-text-wrapper">
                              <Badge
                                overlap="rectangular"
                                color="primary"
                                badgeContent={Number(e.unvotecnt)}
                                invisible={Number(e.unvotecnt) === 0}
                              >
                                <div className={`read-member-name-list`}>{e.title}</div>
                              </Badge>
                              <span style={{ fontSize: "80%", marginLeft: "5px" }}>{e.time}</span>
                            </div>

                            <div style={{ marginRight: "5px" }}>
                              <IconButton
                                aria-label="setting"
                                component="span"
                                onClick={(event) => settingClick(event, e)}
                              >
                                <SettingsIcon />
                              </IconButton>
                            </div>
                          </div>
                        </StyledAnpiWrapper>
                      );
                    })}
              </div>
            </div>

            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={settingClose}>
              {openAnpiId && openAnpiId.istemp == "1" && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCreate(true);
                  }}
                >
                  修正
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemp == "0" && openAnpiId.isend == "0" && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenEndModal(true);
                  }}
                >
                  終了
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenModal(true);
                  }}
                >
                  削除
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "0" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCopy(true);
                  }}
                >
                  複製
                </MenuItem>
              )}
              {openAnpiId && openAnpiId.istemplate == "1" && (
                <MenuItem
                  onClick={() => {
                    settingClose();
                    setOpenAnpiCreate(true);
                  }}
                >
                  テンプレートから作成
                </MenuItem>
              )}
            </Menu>
            <ShowCommon
              open={openPushObj.isopen}
              title="プッシュ通知しますか？"
              handleClose={() => {
                handlePushClose();
              }}
              handleCallBack={() => handlePush()}
            ></ShowCommon>
            <ShowCommon
              open={openModal}
              title="削除しますか？"
              handleClose={() => {
                setOpenModal(false);
              }}
              handleCallBack={() => delAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
            <ShowCommon
              open={openEndModal}
              title="終了しますか？"
              handleClose={() => {
                setOpenEndModal(false);
              }}
              handleCallBack={() => endAnpi(openAnpiId.anpiid)}
            ></ShowCommon>
          </div>

          {loading ? (
            <div>
              <StyledCircularProgress />
            </div>
          ) : selectedAnpiid && selectedIsTemp == false ? (
            <div className="anpi-member-list-right">
              {/* <AppBar position="static" color="default"> */}
              <Tabs value={value} onChange={handleChange} aria-label="simple tabs example" variant="fullWidth">
                <Tab label="概要" />
                {/* <Tab label="チャート" /> */}
                <Tab label="詳細" />
              </Tabs>
              {/* </AppBar> */}

              {value == 0 ? (
                <div>
                  <div>
                    <StyledTypography variant="h5" component="div">
                      施設別
                    </StyledTypography>
                    <TableContainer style={{ overflowX: "visible" }}>
                      <StyledTable
                        //className={classes.table}
                        aria-labelledby="tableTitle"
                        //size={dense ? "small" : "medium"}
                        size="small"
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead
                          //classes={classes}
                          //numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          //onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          //rowCount={roomsList.length}
                        />

                        <StyledTableBody>
                          {stableSort(alldata, getComparator(order, orderBy))
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                              // const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={row.hpname}>
                                  <TableCell align="left">{row.hpname}</TableCell>
                                  {/* <TableCell align="left">{row.unitname}</TableCell> */}
                                  <TableCell align="left">
                                    <StyledCntLink
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        setOpenChartObj({
                                          kbn: "hp",
                                          vote: "all",
                                          hpcode: row.hpcode,
                                          unitcode: row.unitcode,
                                          anpiid: row.anpiid,
                                          isopen: true,
                                          title: row.hpname + " 合計",
                                        });
                                      }}
                                    >
                                      {row.all}
                                    </StyledCntLink>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.vote != "0" ? (
                                      <StyledCntLink
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          setOpenChartObj({
                                            kbn: "hp",
                                            vote: "vote",
                                            hpcode: row.hpcode,
                                            unitcode: row.unitcode,
                                            anpiid: row.anpiid,
                                            isopen: true,
                                            title: row.hpname + " 済",
                                          });
                                        }}
                                      >
                                        {row.vote}
                                      </StyledCntLink>
                                    ) : (
                                      "0"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.novote != "0" ? (
                                      <StyledCntLink
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          setOpenChartObj({
                                            kbn: "hp",
                                            vote: "novote",
                                            hpcode: row.hpcode,
                                            unitcode: row.unitcode,
                                            anpiid: row.anpiid,
                                            isopen: true,
                                            title: row.hpname + " 未",
                                          });
                                        }}
                                      >
                                        {row.novote}
                                      </StyledCntLink>
                                    ) : (
                                      "0"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </StyledTableBody>
                      </StyledTable>
                    </TableContainer>
                  </div>

                  <div>
                    <StyledTypography variant="h5" component="div">
                      部署別
                    </StyledTypography>
                    <TableContainer style={{ overflowX: "visible" }}>
                      <StyledTable
                        //className={classes.table}
                        aria-labelledby="tableTitle"
                        //size={dense ? "small" : "medium"}
                        size="small"
                        aria-label="enhanced table"
                      >
                        <EnhancedTableHead_unit
                          //classes={classes}
                          //numSelected={selected.length}
                          order={order}
                          orderBy={orderBy}
                          //onSelectAllClick={handleSelectAllClick}
                          onRequestSort={handleRequestSort}
                          //rowCount={roomsList.length}
                        />

                        <StyledTableBody>
                          {stableSort(sumdata, getComparator(order, orderBy))
                            .slice(sumPage * sumRowsPerPage, sumPage * sumRowsPerPage + sumRowsPerPage)
                            .map((row) => {
                              // const labelId = `enhanced-table-checkbox-${index}`;
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.hpname + row.unitname} // {row.unitname}
                                >
                                  <TableCell align="left">{row.hpname}</TableCell>
                                  <TableCell align="left">{row.unitname}</TableCell>
                                  <TableCell align="left">
                                    <StyledCntLink
                                      component="button"
                                      variant="body2"
                                      onClick={() => {
                                        setOpenChartObj({
                                          kbn: "unit",
                                          vote: "all",
                                          hpcode: row.hpcode,
                                          unitcode: row.unitcode,
                                          anpiid: row.anpiid,
                                          isopen: true,
                                          title: row.hpname + " 合計",
                                        });
                                      }}
                                    >
                                      {row.uall}
                                    </StyledCntLink>
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.vote != "0" ? (
                                      <StyledCntLink
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          setOpenChartObj({
                                            kbn: "unit",
                                            vote: "vote",
                                            hpcode: row.hpcode,
                                            unitcode: row.unitcode,
                                            anpiid: row.anpiid,
                                            isopen: true,
                                            title: row.hpname + " 済",
                                          });
                                        }}
                                      >
                                        {row.uvote}
                                      </StyledCntLink>
                                    ) : (
                                      "0"
                                    )}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.novote != "0" ? (
                                      <StyledCntLink
                                        component="button"
                                        variant="body2"
                                        onClick={() => {
                                          setOpenChartObj({
                                            kbn: "unit",
                                            vote: "novote",
                                            hpcode: row.hpcode,
                                            unitcode: row.unitcode,
                                            anpiid: row.anpiid,
                                            isopen: true,
                                            title: row.hpname + " 未",
                                          });
                                        }}
                                      >
                                        {row.unovote}
                                      </StyledCntLink>
                                    ) : (
                                      "0"
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </StyledTableBody>
                      </StyledTable>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      component="div"
                      count={sumdata.length}
                      rowsPerPage={sumRowsPerPage}
                      page={sumPage}
                      onPageChange={handleChangeSumPage}
                      onRowsPerPageChange={handleChangeSumRowsPerPage}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {value == 1 ? (
                <StyledDataGrid>
                  <DataGrid
                    hideFooterSelectedRowCount={true}
                    rows={membersList}
                    columns={headCells_member}
                    pagination
                    density={"compact"}
                    slots={{
                      // toolbar: GridToolbar,
                      pagination: CustomPagination,
                      toolbar: CustomToolbar,
                    }}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          honbuid: false,
                        },
                      },
                    }}
                    localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                  ></DataGrid>
                </StyledDataGrid>
              ) : (
                <div></div>
              )}
            </div>
          ) : (
            <div className="anpi-member-list-right"></div>
          )}
        </div>
        <Snackbar
          open={snackopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackOpen(false);
          }}
          message="保存しました。"
        ></Snackbar>
        <Snackbar
          open={snackpushopen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={1000}
          onClose={() => {
            setSnackPushOpen(false);
          }}
          message="プッシュ通知しました。"
        ></Snackbar>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowAnpiList;
