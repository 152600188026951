import React, { useContext, useState, useEffect, useMemo } from "react";
import "./ChatMember.css";
import { Store } from "../Store/store";
import { STATIC_URL, custom_axios, API_URL } from "../Common/Common";
import ShowUserInfo from "../Common/ShowUserInfo";
import ShowMember from "../Common/ShowMember";
import ShowNote from "../Common/ShowNote";
import { useHistory } from "react-router-dom";
import ShowCommon from "../Common/ShowCommon";
import ShowPhotos from "../Common/ShowPhotos";
import ShowCalendar from "../Common/ShowCalendar";
// import ShowCall from "../Common/ShowCall";
import ShowReservationList from "../Common/ShowReservationList";
import ShowRoomAccount from "../Common/ShowRoomAccount";
import Search from "@mui/icons-material/Search";
import { FaAngleDown, FaAngleUp, FaPlus, FaCircle } from "react-icons/fa";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { RiPushpin2Line } from "react-icons/ri";
import { MdNotificationsOff, MdVisibility, MdVisibilityOff } from "react-icons/md";
import styled from "@emotion/styled";

const StyledDivAccordion = styled("div")`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  height: 39px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const StyledIconButton = styled(IconButton)`
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

const StyledSearch = styled(Search)`
  color: ${(props) => props.theme.palette.primary.main};
`;
const StyledFlexibleDiv = styled("div")`
  display: flex;
`;
const StyledMemberWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2px;
  background: ${(props) => (props.bigfont ? props.theme.palette.primary.light : "#f7f8fc")};
  cursor: pointer;
  transition: width 0.3s;
  -webkittransition: width 0.3s;
  position: relative;
  z-index: 0;
  &:hover {
    font-weight: 600;
    background-color: ${(props) => props.theme.palette.primary.light};
    & .member-name {
      font-weight: 600;
    }
    & .member-image {
      border: 1px solid white;
    }
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledFcolor = styled("div")`
  color: ${(props) => props.theme.palette.primary.contrastText};
`;

function ChatMember() {
  const history = useHistory();

  const { state, dispatch, hubConnection } = useContext(Store);
  const [open, setOpen] = useState(false);
  const [openPhoto, setOpenPhoto] = useState(false);
  const [openReservation, setOpenReservation] = useState(false);
  const [openNote, setOpenNote] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openRoomName, setOpenRoomName] = useState(false);
  const [honbuid, setHonbuid] = useState("");
  const [chatsFilter, setChatsFilter] = useState("");
  const [localMemberList, setLocalMemberList] = useState([]);

  const [menuexpanded, setMenuExpanded] = React.useState(true);
  const [memberexpanded, setMemberExpanded] = React.useState(true);
  const [MiniRoomExpanded, setMiniRoomExpanded] = React.useState(true);
  const [createMiniRoomOpen, setCreateMiniRoomOpen] = useState(false);
  const [membersHonbuidList, setMembersHonbuidList] = useState([]);
  const [showMiniRoomIncludesHide, setShowMiniRoomIncludesHide] = useState(false);
  // const [openCall, setOpenCall] = useState({ open: false, channel: "", uid: "", name: "", flag: "" });
  // const handleCallClose = () => {
  //   setOpenCall({ ...openCall, open: false });
  // };
  const handleChange = (e) => {
    setChatsFilter(e.target.value);
  };
  const handleClickOpen = (honbuid) => {
    setHonbuid(honbuid);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenPhoto = (honbuid) => {
    setHonbuid(honbuid);
    setOpenPhoto(true);
  };

  const handlePhotoClose = () => {
    setOpenPhoto(false);
  };

  const handleClickOpenReservation = (honbuid) => {
    setHonbuid(honbuid);
    setOpenReservation(true);
  };

  const handleReservationClose = () => {
    setOpenReservation(false);
  };

  const handleNoteClose = () => {
    setOpenNote(false);
  };
  const handleCalendarClose = () => {
    setOpenCalendar(false);
  };
  const handleRoomNameClose = () => {
    setOpenRoomName(false);
  };
  const handleCreateMiniRoomOpen = () => {
    setCreateMiniRoomOpen(true);
    const tmpList = [];
    for (var ele in localMemberList) {
      tmpList.push(localMemberList[ele].honbuid);
    }
    setMembersHonbuidList(tmpList);
  };
  const handleCreateMiniRoomClose = () => {
    setCreateMiniRoomOpen(false);
    setMembersHonbuidList([]);
    setSroomname("");
  };

  useEffect(() => {
    // 親部屋・小部屋判定の心臓部
    let tmpCurrentParentRoomId = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].parentroomid;
    if (tmpCurrentParentRoomId === state.currentRoomid) {
      // 親
      dispatch({ type: "CHANGE_ISMINIROOM", isMiniRoom: false });
      dispatch({ type: "CHANGE_PARENTROOMID", parentroomid: state.currentRoomid });
      setShowMiniRoomIncludesHide(false);
    } else {
      // 小部屋
      dispatch({ type: "CHANGE_ISMINIROOM", isMiniRoom: true });
      dispatch({ type: "CHANGE_PARENTROOMID", parentroomid: tmpCurrentParentRoomId });
    }
  }, [state.currentRoomid]);

  useEffect(() => {
    if (chatsFilter !== "") {
      const updateList = [
        {
          honbuid: state.honbuid,
          name: state.user_name,
          hpname: state.hpname,
          subname: state.subname,
          isavatar: state.isavatar,
          fileno: state.fileno,
          memo: state.memo,
          isvip: state.isvip,
          isadmin: state.isadmin,
          ismark: state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismarkme,
        },
        ...state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist,
      ].filter((item) => {
        if (chatsFilter && String(item.name).indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter && String(item.hpname).indexOf(chatsFilter) !== -1) {
          return true;
        }
        if (chatsFilter && String(item.subname).indexOf(chatsFilter) !== -1) {
          return true;
        }

        return false;
      });
      setLocalMemberList(updateList);
    } else {
      setLocalMemberList([
        {
          honbuid: state.honbuid,
          name: state.user_name,
          hpname: state.hpname,
          subname: state.subname,
          isavatar: state.isavatar,
          fileno: state.fileno,
          memo: state.memo,
          isvip: state.isvip,
          isadmin: state.isadmin,
          ismark: state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismarkme,
        },
        ...state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist,
      ]);
    }
  }, [chatsFilter, state, memberexpanded]);

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
    handleClose();
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [sroomname, setSroomname] = useState("");

  const [openModal, setOpenModal] = useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const [openDelMinModal, setOpenDelMinModal] = useState(false);
  const handleDelMinModalClose = () => {
    setOpenDelMinModal(false);
  };

  const [openDelModal, setOpenDelModal] = useState(false);
  const handleDelModalClose = () => {
    setOpenDelModal(false);
  };

  const [openHideModal, setOpenHideModal] = useState(false);
  const handleHideModalClose = () => {
    setOpenHideModal(false);
  };

  const handleDelMinRoom = async () => {
    handleDelMinModalClose();
    const mainhonbuids = membersHonbuidList.join("_"); // ルームメンバーの本部IDを _ で繋いだ文字列
    const result = await custom_axios(
      API_URL + `/DelMiniRoom?honbuid=${state.honbuid}&mainhonbuids=${mainhonbuids}&roomid=${state.currentRoomid}`
    );
    const roomname = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].roomname;
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      // 親へ小部屋作成infoを流す
      hubConnection.invoke(
        "SendMessage",
        localStorage.getItem("parentRoomid"),
        state.honbuid,
        state.user_name,
        `小部屋「${roomname}」が削除されました。`,
        "information"
      );

      // 親部屋へ移動
      dispatch({
        type: "CHANGE_ROOM",
        payload: { to: localStorage.getItem("parentRoomid"), from: state.currentRoomid },
      });
      localStorage.setItem("currentRoomid", localStorage.getItem("parentRoomid"));
      localStorage.setItem("isbot", "0");
    }
  };
  const handleLeaveRoom = async () => {
    setOpenModal(false);
    dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/delUserFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      hubConnection.invoke(
        "SendMessage",
        state.currentRoomid,
        state.honbuid,
        state.user_name,
        state.user_name + " が退室しました",
        "information"
      );
    }
  };
  const handleDeleteRoom = async () => {
    setOpenDelModal(false);
    dispatch({ type: "CHANGE_ROOM", payload: { to: "", from: "" } });
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/delUserFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const handleChangeHideRoom = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const apistr = API_URL + `/hideRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`;
    const result = await custom_axios(apistr);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    dispatch({ type: "CHANGE_HIDE", payload: { roomid: state.currentRoomid } });
    setOpenHideModal(false);
  };

  const handleShowMiniRoomIncludesHide = () => {
    setShowMiniRoomIncludesHide(!showMiniRoomIncludesHide);
  };

  // const handleCall = async () => {
  //   let honbuid = localStorage.getItem("honbuid");
  //   setOpenCall({ open: true, channel: state.currentRoomid, uid: honbuid, name: state.user_name, flag: "create" });
  // };
  const handleOnOff = async () => {
    let honbuid = localStorage.getItem("honbuid");
    //setAnchorEl(null);
    var result = await custom_axios(API_URL + `/changePushFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 200) {
      dispatch({ type: "CHANGE_NOTIFICATION", payload: state.currentRoomid });
    }
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleStarOnOff = async () => {
    let honbuid = localStorage.getItem("honbuid");
    //setAnchorEl(null);
    var result = await custom_axios(API_URL + `/changeStarFromRoom?roomid=${state.currentRoomid}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 200) {
      dispatch({ type: "CHANGE_STAR", payload: state.currentRoomid });
    }
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  let isOfficial = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isofficial;
  //let isRoomName = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].roomname;
  let isMemberList = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length;
  let localisdirection = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isdirection;
  let localisbukai = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isbukai;
  let isbotroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isbotroom;
  let ismyroom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ismyroom;
  const MemoAvatar = useMemo(() => {
    return <ShowUserInfo open={open} honbuid={honbuid} handleClose={handleClose} handleMeClose={() => {}} />;
  }, [open, honbuid]);

  const MemoRoomAccount = useMemo(() => {
    return <ShowRoomAccount open={openRoomName} handleClose={handleRoomNameClose} />;
  }, [openRoomName]);

  const MemoReservation = useMemo(() => {
    return <ShowReservationList open={openReservation} handleClose={handleReservationClose} />;
  }, [openReservation]);

  const MemoNote = useMemo(() => {
    return <ShowNote open={openNote} handleClose={handleNoteClose}></ShowNote>;
  }, [openNote]);
  const MemoCalendar = useMemo(() => {
    return (
      <ShowCalendar
        open={openCalendar}
        handleClose={handleCalendarClose}
        honbuid={state.honbuid}
        roomid={state.currentRoomid}
      ></ShowCalendar>
    );
  }, [openCalendar, state.currentRoomid]);

  const MemoPhoto = useMemo(() => {
    return <ShowPhotos open={openPhoto} handleClose={handlePhotoClose} />;
  }, [openPhoto]);

  const handleMemberClick = async () => {
    setMemberExpanded(!memberexpanded);
  };

  const handleMiniRoomAccordionClick = async () => {
    setMiniRoomExpanded(!MiniRoomExpanded);
  };

  // 小部屋一覧の表示内容をstoreからfilterしてsort
  const filtedMiniRoomList = () => {
    let tmpList = [];
    // filter条件↓
    // parentroomidがstateに保存していた親のid
    // parentroomidが自分のroomidではない（親ではない）
    // 全表示（目のアイコン）非選択の場合、ishide==="0"のみ表示
    if (showMiniRoomIncludesHide) {
      tmpList = state.chatrooms.filter((f) => f.parentroomid === state.parentroomid && f.parentroomid !== f.roomid);
    } else {
      // こっちがデフォルト
      tmpList = state.chatrooms.filter(
        (f) => f.parentroomid === state.parentroomid && f.parentroomid !== f.roomid && f.ishide === "0"
      );
    }

    // (親から小部屋に移動したときにfilter条件が変わらないので、再レンダリングで画面がガタつかない)
    const tmpSortedList = tmpList.sort(function (a, b) {
      if (a.isstar < b.isstar) {
        return 1;
      }
      if (a.isstar > b.isstar) {
        return -1;
      }
      if (a.latestsending < b.latestsending) {
        return 1;
      }
      if (a.latestsending > b.latestsending) {
        return -1;
      }
      return 0;
    });
    return tmpSortedList;
  };

  const handleCreateMiniRoom = async () => {
    //getパラメータには、大量の本部IDは載せないようにする
    const mainhonbuids = "1000000"; //membersHonbuidList.join("_"); // ルームメンバーの本部IDを _ で繋いだ文字列
    let retroomid = "";
    const result = await custom_axios(
      API_URL +
        `/CreateMiniRoom?honbuid=${state.honbuid}&mainhonbuids=${mainhonbuids}&roomname=${sroomname}&parentroomid=${state.currentRoomid}`
    );
    const status = result.status;
    if (status === 401) {
      handleCreateMiniRoomClose();
      history.replace("/loginError");
    }
    if (status === 200) {
      handleCreateMiniRoomClose();
      fetchData("").then(() => {
        retroomid = result.data.roomid;
        // 親へ小部屋作成infoを流す
        hubConnection.invoke(
          "SendMessage",
          state.currentRoomid,
          state.honbuid,
          state.user_name,
          `小部屋「${sroomname}」が作成されました。`,
          "information"
        );
        // 小部屋にも部屋作成のinfo流しておく
        hubConnection.invoke(
          "SendMessage",
          retroomid,
          state.honbuid,
          state.user_name,
          "小部屋が作成されました。",
          "information"
        );
        // 小部屋へ移動
        dispatch({ type: "CHANGE_ROOM", payload: { to: retroomid, from: state.currentRoomid } });
        localStorage.setItem("currentRoomid", retroomid);
        localStorage.setItem("isbot", "0");
      });
    }
  };

  const fetchData = async (roomid) => {
    let honbuid = localStorage.getItem("honbuid");
    const httpstring =
      roomid !== ""
        ? `/getConversationList?honbuid=${honbuid}`
        : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
    const result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      return result.data;
    }
  };

  return (
    <div>
      {/* <div className="member-content">
        <div className="chat-member-num">メンバー</div>
        {state.chatrooms &&
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid).length > 0 &&
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length > 0 && (
            <div className="chat-member-num">
              {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length + 1}人
            </div>
          )}
      </div> */}
      <div className="chat-search-member">
        <div className="chat-search-icon">
          <StyledSearch></StyledSearch>
          {/* <Search className={classes.iconcolor} /> */}
        </div>
        <input
          placeholder="メンバー検索"
          value={chatsFilter}
          name="chatsFilter"
          onChange={handleChange}
          className="chat-search-field"
        />
      </div>
      {/* <ShowUserInfo open={open} honbuid={honbuid} handleClose={handleClose} /> */}
      {/* {MemoCall} */}
      {MemoAvatar}
      {MemoRoomAccount}
      {MemoReservation}
      {MemoNote}
      {MemoCalendar}
      {MemoPhoto}
      <div className="member-list">
        {ismyroom == "1" || isbotroom == "1" ? (
          <>
            <StyledDivAccordion onClick={() => setMenuExpanded(!menuexpanded)}>
              <div className="ac-group" style={{ marginLeft: "6px" }}>
                <div>メニュー</div>
              </div>
              <StyledIconButton size={"small"}>{menuexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
            </StyledDivAccordion>
            <div style={{ display: menuexpanded ? "" : "none" }}>
              {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist.length > 1 && (
                <StyledMemberWrapper onClick={() => setOpenRoomName(true)}>
                  <div className="menu-content">部屋情報</div>
                </StyledMemberWrapper>
              )}

              <StyledMemberWrapper
                onClick={async () => {
                  await custom_axios(API_URL + `/getNewTempToken?honbuid=${state.honbuid}&kbn=calendar`);
                  setOpenCalendar(true);
                }}
              >
                <div className="menu-content">カレンダー</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={() => setOpenNote(true)}>
                <div className="menu-content">ノート</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={handleClickOpenReservation}>
                <div className="menu-content">メッセージ予約</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={handleClickOpenPhoto}>
                <div className="menu-content">ファイル/写真</div>
              </StyledMemberWrapper>
            </div>
          </>
        ) : (
          <div style={{ marginLeft: "0.5px", marginRight: "0.5px" }}>
            <StyledDivAccordion
              onClick={() => {
                setMenuExpanded(!menuexpanded);
              }}
            >
              <div className="ac-group" style={{ marginLeft: "6px" }}>
                <div>メニュー</div>
              </div>
              <StyledIconButton size={"small"}>{menuexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
            </StyledDivAccordion>

            <div style={{ display: menuexpanded ? "" : "none" }}>
              {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isgroup == 1 && (
                <StyledMemberWrapper onClick={() => setOpenRoomName(true)}>
                  <div className="menu-content">部屋情報</div>
                </StyledMemberWrapper>
              )}

              <StyledMemberWrapper
                onClick={async () => {
                  await custom_axios(API_URL + `/getNewTempToken?honbuid=${state.honbuid}&kbn=calendar`);
                  setOpenCalendar(true);
                }}
              >
                <div className="menu-content">カレンダー</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={() => setOpenNote(true)}>
                <div className="menu-content">ノート</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={handleClickOpenReservation}>
                <div className="menu-content">メッセージ予約</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={handleClickOpenPhoto}>
                <div className="menu-content">ファイル/写真</div>
              </StyledMemberWrapper>

              {isOfficial == "0" && !state.isMiniRoom && ["1", "2", "3", "5", "6"].includes(state.isadmin) && (
                <StyledMemberWrapper onClick={handleFaClickOpen}>
                  <div className="menu-content">メンバー追加</div>
                </StyledMemberWrapper>
              )}

              <StyledMemberWrapper onClick={handleOnOff}>
                <div className="menu-content">通知ON/OFF</div>
              </StyledMemberWrapper>

              <StyledMemberWrapper onClick={handleStarOnOff}>
                <div className="menu-content">ピン留めON/OFF</div>
              </StyledMemberWrapper>

              {state.isMiniRoom && (
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenHideModal(true);
                    console.log(filtedMiniRoomList());
                  }}
                >
                  <div className="menu-content">
                    {state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ishide === "0" ? (
                      <>この小部屋を非表示</>
                    ) : (
                      <>この小部屋の非表示を解除</>
                    )}
                  </div>
                </StyledMemberWrapper>
              )}
              {state.isMiniRoom && (
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenDelMinModal(true);
                  }}
                >
                  <div className="menu-content">
                    <>この小部屋を削除</>
                  </div>
                </StyledMemberWrapper>
              )}
              {isOfficial == "0" && localisdirection == "0" && isMemberList > "1" && !state.isMiniRoom && (
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenModal(true);
                  }}
                >
                  <div className="menu-content">会話から抜ける</div>
                </StyledMemberWrapper>
              )}
              {isOfficial == "0" && localisdirection == "0" && isMemberList == "0" && !state.isMiniRoom && (
                <StyledMemberWrapper
                  onClick={() => {
                    handleClose();
                    setOpenDelModal(true);
                  }}
                >
                  <div className="menu-content">削除</div>
                </StyledMemberWrapper>
              )}
            </div>
          </div>
        )}
        <div style={{ marginLeft: "0.5px", marginRight: "0.5px" }}>
          <StyledDivAccordion onClick={() => handleMiniRoomAccordionClick()}>
            <div className="ac-group" style={{ marginLeft: "6px" }}>
              <div>小部屋</div>
            </div>
            <StyledIconButton
              size={"small"}
              onClick={(e) => {
                e.stopPropagation();
                handleShowMiniRoomIncludesHide();
              }}
            >
              {showMiniRoomIncludesHide ? <MdVisibility /> : <MdVisibilityOff />}
            </StyledIconButton>
            {/* 小部屋を追加できるのは親部屋にいるときだけ */}
            {!state.isMiniRoom && (
              <StyledIconButton
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleCreateMiniRoomOpen();
                }}
              >
                <FaPlus />
              </StyledIconButton>
            )}
            <StyledIconButton size={"small"} onClick={() => handleMiniRoomAccordionClick()}>
              {MiniRoomExpanded ? <FaAngleUp /> : <FaAngleDown />}
            </StyledIconButton>
          </StyledDivAccordion>
          <div style={{ display: MiniRoomExpanded ? "" : "none" }}>
            {/* 小部屋表示アリア */}
            {/* 親かつ小部屋未作成 */}
            {!state.isMiniRoom && filtedMiniRoomList().length === 0 && (
              <StyledMemberWrapper onClick={() => handleCreateMiniRoomOpen()}>
                <div className="menu-content">新規小部屋の作成</div>
              </StyledMemberWrapper>
            )}
            {/* 小部屋あり */}
            {filtedMiniRoomList().length > 0 &&
              filtedMiniRoomList().map((e) => (
                <StyledMemberWrapper
                  key={e.roomid}
                  bigfont={state.currentRoomid === e.roomid}
                  onClick={() => {
                    if (e.roomid == state.currentRoomid) {
                      dispatch({
                        type: "CHANGE_ROOM",
                        payload: { to: localStorage.getItem("parentRoomid"), from: e.roomid },
                      });
                      localStorage.setItem("currentRoomid", localStorage.getItem("parentRoomid"));
                    } else {
                      dispatch({ type: "CHANGE_ROOM", payload: { to: e.roomid, from: state.currentRoomid } });
                      localStorage.setItem("currentRoomid", e.roomid);
                      localStorage.setItem("isbot", "0");
                    }
                  }}
                >
                  <div id="container">
                    <div id="itemA">
                      {parseInt(e.unreadcnt) !== 0 && (
                        <FaCircle
                          style={{
                            color: `${state.color}`,
                            fontSize: "10px",
                            marginRight: "5px",
                            marginTop: "10px",
                          }}
                        />
                      )}
                      {e.ishide == "1" && (
                        <div style={{ fontSize: "14px", marginRight: "5px", marginTop: "2px" }}>
                          <MdVisibilityOff />
                        </div>
                      )}
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {e.roomname}
                      </div>
                    </div>
                    <div id="itemB" className="member-miniroom-icon-wrapper">
                      {e.ispush == "0" && <MdNotificationsOff />}
                    </div>
                    <div id="itemC" className="member-miniroom-icon-wrapper">
                      {e.isstar == "1" && <RiPushpin2Line />}
                    </div>
                  </div>
                </StyledMemberWrapper>
              ))}
          </div>
        </div>

        <div style={{ marginLeft: "0.5px", marginRight: "0.5px" }}>
          <StyledDivAccordion onClick={() => handleMemberClick()}>
            <div className="ac-group" style={{ marginLeft: "6px" }}>
              <div>メンバー</div>
            </div>
            <StyledIconButton size={"small"}>{memberexpanded ? <FaAngleUp /> : <FaAngleDown />}</StyledIconButton>
          </StyledDivAccordion>
          <div style={{ display: memberexpanded ? "" : "none" }}>
            {localMemberList.map((e) => (
              <StyledMemberWrapper key={e.honbuid} onClick={() => handleClickOpen(e.honbuid, e.isvip, e.isadmin)}>
                <div className="member-content">
                  <img
                    src={STATIC_URL + `/downloadAvatar?honbuid=${e.honbuid}&fileno=${e.fileno}&size=small`}
                    width="30"
                    height="30"
                    alt=""
                    className="member-image"
                    //style={isCurrent ? { border: "1px solid white" } : null}
                  />
                  <div className="member-text-vip-wrapper">
                    <StyledFlexibleDiv>
                      {e.ismark == "1" && localisdirection == "1" && (
                        <StyledChip variant="outlined" size="small" label="発言権"></StyledChip>
                      )}
                      {e.isvip == "1" && (
                        <StyledChip variant="outlined" color="primary" size="small" label="VIP"></StyledChip>
                      )}
                      {e.isbukaiadmin == "1" && (
                        <StyledChip variant="outlined" color="primary" size="small" label="部会管理"></StyledChip>
                      )}
                      {e.isadmin == "1" ? (
                        <StyledChip variant="outlined" color="primary" size="small" label="システム管理"></StyledChip>
                      ) : e.isadmin == "2" ? (
                        <StyledChip variant="outlined" color="primary" size="small" label="施設管理"></StyledChip>
                      ) : e.isadmin == "3" ? (
                        <StyledChip variant="outlined" color="primary" size="small" label="部屋管理"></StyledChip>
                      ) : e.isadmin == "6" ? (
                        <StyledChip variant="outlined" color="primary" size="small" label="施設横断部屋"></StyledChip>
                      ) : e.isadmin == "4" ? (
                        <StyledChip variant="outlined" color="primary" size="small" label="ユーザー管理"></StyledChip>
                      ) : e.isadmin == "5" ? (
                        <StyledChip
                          variant="outlined"
                          color="primary"
                          size="small"
                          label="部屋・ユーザー管理"
                        ></StyledChip>
                      ) : (
                        <div></div>
                      )}
                    </StyledFlexibleDiv>
                    <div className="member-name-wrapper">
                      <div>
                        {e.name}
                        {localisbukai == "1" ? (
                          <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.hpname})</span>
                        ) : (
                          <span style={{ fontSize: "80%", marginLeft: "5px" }}>({e.subname})</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </StyledMemberWrapper>
            ))}
          </div>
        </div>
      </div>
      <ShowMember open={openFa} handleClose={handleFaClose} isadduser={true} roomid={state.currentRoomid} />
      <ShowCommon
        open={openModal}
        title="会話から抜けますか？"
        handleClose={handleModalClose}
        handleCallBack={handleLeaveRoom}
      ></ShowCommon>
      <ShowCommon
        open={openDelMinModal}
        title="小部屋を削除しますか？"
        handleClose={handleDelMinModalClose}
        handleCallBack={handleDelMinRoom}
      ></ShowCommon>
      <ShowCommon
        open={openDelModal}
        title="削除しますか？"
        handleClose={handleDelModalClose}
        handleCallBack={handleDeleteRoom}
      ></ShowCommon>
      <ShowCommon
        open={openHideModal}
        title={
          state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].ishide === "0"
            ? "非表示にしますか？"
            : "非表示を解除しますか？"
        }
        handleClose={handleHideModalClose}
        handleCallBack={handleChangeHideRoom}
      ></ShowCommon>
      <Dialog open={createMiniRoomOpen} onClose={handleCreateMiniRoomClose} maxWidth={"sm"}>
        <StyledDialogTitle id="max-width-dialog-title">
          <StyledFcolor>小部屋作成</StyledFcolor>
        </StyledDialogTitle>

        <DialogContent></DialogContent>

        <DialogActions>
          <Grid container>
            <input
              style={{ marginRight: "10px" }}
              placeholder="新規小部屋名を入力"
              value={sroomname}
              name="sroomname"
              onChange={(e) => {
                if (e.target.value.length <= 30) {
                  // DBの都合で上限30文字
                  setSroomname(e.target.value);
                }
              }}
              //className="show-create-room-field"
              className={["show-create-room-field", sroomname.length === 0 && "plus-background"].join(" ")}
            />

            <Button
              style={{ marginRight: "5px" }}
              variant="contained"
              color="primary"
              onClick={handleCreateMiniRoom}
              disabled={!sroomname}
            >
              作成
            </Button>

            <Button onClick={handleCreateMiniRoomClose} variant="outlined" color="primary">
              閉じる
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ChatMember;
