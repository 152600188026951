/* eslint-disable react/prop-types */
/* eslint-disable require-atomic-updates */
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Store } from "../Store/store";
import { Tooltip } from "@mui/material";
import Message from "./Message";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import "./MessageList.css";
//import ScrollToBottom from "react-scroll-to-bottom";
//import throttle from "lodash.throttle";
import axios from "axios";
import { API_URL, custom_axios, GOOD_EMOJI } from "../Common/Common";
import { useHistory } from "react-router-dom";
import ShowReadMember from "../Common/ShowReadMember";
import ShowEditMessage from "../Common/ShowEditMessage";
import ShowGetEditMessage from "../Common/ShowGetEditMessage";
//import ShowGoodMember from "../Common/ShowGoodMember";
import ShowNewQueVote from "../Common/ShowNewQueVote";
import ShowReply from "../Common/ShowReply";
import ShowUserInfo from "../Common/ShowUserInfo";
import ShowCommon from "../Common/ShowCommon";
import ShowImageInfo from "../Common/ShowImageInfo";
import ShowBotImageInfo from "../Common/ShowBotImageInfo";
import ShowVideoInfo from "../Common/ShowVideoInfo";
import ShowAnpiVote from "../Common/ShowAnpiVote";
import styled from "@emotion/styled";
import ojigiman from "../image/ojigiman.gif";
const StyledOjigimanDiv = styled("div")`
  width: 100%;
  text-align: center;
`;

function MessageList({
  isSearched,
  setIsSearched,
  rootRef,
  isPreSend,
  setIsPreSend,
  setIsReply,
  isBukai,
  //isMark,
  //unReadCnt,
  setUnReadCnt,
  fireScrollBottom,
  //setFireScrollBottom,
}) {
  const history = useHistory();
  const [firstOpen, setFirstOpen] = useState(false);
  const [botUrl, setBotUrl] = useState("");
  const [botImgOpen, setBotImgOpen] = useState(false);
  const { state, dispatch } = useContext(Store);
  const [localChat, setLocalChats] = useState([]);
  //const [initQuestionid, setInitQuestionid] = useState(null);
  const [initMessageid, setInitMessageid] = useState(null);
  const [initHonbuid, setInitHonbuid] = useState("");
  const [isScrollBottom, setIsScrollBottom] = useState(true);

  /* Listの中で、ある特定の位置を保存するためのRef */
  const myRef = useRef(null);
  const myRefMessageid = useRef(null);

  /* List親 */
  //const rootRef = useRef(null);

  /* 追加読み込み中かのRef */
  const messageLoading = useRef(false);

  const me = localStorage.getItem("honbuid");

  // localCahtsを更新
  useEffect(() => {
    //console.log("chatsFilterの値", state.chatsFilter);
    if (state.chatrooms && state.chatrooms.length > 0) {
      //console.log("state.chatrooms", state.chatrooms);
      const updateList = state.chatrooms.filter((item) => {
        return item.roomid === state.currentRoomid;
      });
      if (updateList.length > 0) {
        setLocalChats(updateList[0].conversationlist);
        setUnReadCnt(updateList[0].unreadcnt);
      } else {
        setLocalChats([]);
      }
    }
  }, [state.currentRoomid, state.addConversationCnt, state.chatrooms[0].conversationlist]);

  // localCahtが更新された時
  useEffect(() => {
    // 未読数計算
    setUnReadCnt(localChat.filter((ele) => ele.unreaded === "1").length);

    // 上にスクロールしていったら、新規メッセージを取得していく仕組み
    const observer = new IntersectionObserver(callback, {
      root: document.querySelector(".messages-area"),
      rootMargin: "0px",
      threshold: 0,
    });
    //console.log("IntersectionObserver", rootRef.current);
    let messages = document.querySelectorAll(".message-wrapper");
    /* 監視開始 */
    messages.forEach((target) => observer.observe(target));
    /* ★新規メッセージ受信 or 初回起動 の場合のみ、強制的に↓にいく */
    // messageLoading: 上にスクロールしてメッセージをローディング中
    if (firstOpen) {
      // 部屋を開いた時
      rootRef.current.scrollTop = rootRef.current.scrollHeight;
      setFirstOpen(false);
    } else if (messageLoading.current === false) {
      // 新規メッセージが来た時
      if (isScrollBottom) {
        // 一番下にいるとき
        rootRef.current.scrollTop = rootRef.current.scrollHeight;
      }
    }
    // スクロールの位置調整
    if (myRefMessageid.current && myRef.current) {
      // 新規メッセージ読み込んだ時、★の処理で一番下にいってしまうので、読み込んだ地点に戻るためのもの
      //window.scrollTo(0, myRef.current.offsetTop);
      rootRef.current.scrollTop = myRef.current.offsetTop;
      myRefMessageid.current = null;
    } else if (myRefMessageid.current) {
      // 謎
      rootRef.current.scrollTop = 100;
    }

    if (isPreSend) {
      rootRef.current.scrollTop = rootRef.current.scrollHeight;
      setIsPreSend(false);
    }
    /* 監視解除 */
    return () => {
      messages.forEach((target) => observer.unobserve(target));
      //console.log("速度", "監視終了");
    };
    // isActive変更時にもObserverを更新
    // （新規メッセージを消したり出したりした時点で監視対象齟齬が生じるため）
  }, [localChat, state.isActive]);

  useEffect(() => {
    const fetchData = async () => {
      const updateList = state.chatrooms.filter((item) => {
        return item.roomid === state.currentRoomid;
      })[0].conversationlist;

      if (updateList.length === 0) return;
      const lastMessageid = updateList[updateList.length - 1].messageid;
      myRefMessageid.current = lastMessageid;
      /* const sqlstr =
        API_URL +
        `/GetAllConversationListOne?roomid=${state.currentRoomid}&honbuid=${state.honbuid}&messageid=${lastMessageid}`;
      console.log("new", sqlstr);
      const result = await axios(sqlstr);
      const status = result.status;
      //console.log("get結果", result);
      if (status === 200) {
        //node.current.scrollTop = 1;
        dispatch({ type: "SET_CONVERSASTION", payload: result.data.conversations });
      }
      console.log("loadproc", "検索ロード終了"); */
    };

    if (isSearched) {
      setIsSearched(false);
      fetchData();
    }
    //rootRef.current.scrollTop = 100;
    //console.log("loadproc", node.current.scrollTop);
    //console.log("loadproc", loading);
    //loadproc();
  }, [isSearched]);

  useEffect(() => {
    rootRef.current.scrollTop = rootRef.current.scrollHeight;
  }, [fireScrollBottom]);

  const [openRead, setOpenRead] = useState({ open: false, kbn: "message" });
  const [openEdit, setOpenEdit] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  //const [docurl, setDocurl] = useState({ open: false, url: "" });
  const [openEditMessage, setOpenEditMessage] = useState(false);

  const [avataropen, setAvatarOpen] = React.useState(false);
  const [imgopen, setImgOpen] = React.useState(false);
  const [videoopen, setVideoOpen] = React.useState(false);
  // const [callopen, setCallOpen] = React.useState(false);
  // const handleDocClose = () => {
  //   setDocurl({ ...docurl, open: false });
  // };
  const handleAvatarClose = () => {
    setAvatarOpen(false);
  };
  const handleImgClose = () => {
    setImgOpen(false);
  };
  const handleBotImgClose = () => {
    setBotImgOpen(false);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
  };
  // const handleCallClose = () => {
  //   setCallOpen(false);
  // };
  const MemoAvatar = useMemo(() => {
    return (
      <ShowUserInfo open={avataropen} honbuid={initHonbuid} handleClose={handleAvatarClose} handleMeClose={() => {}} />
    );
  }, [avataropen, initHonbuid]);
  const MemoImage = useMemo(() => {
    return <ShowImageInfo open={imgopen} messageid={initMessageid} handleClose={handleImgClose} />;
  }, [imgopen, initMessageid]);
  const MemoBotImage = useMemo(() => {
    return <ShowBotImageInfo open={botImgOpen} url={botUrl} handleClose={handleBotImgClose} />;
  }, [botImgOpen, botUrl]);
  const MemoVideo = useMemo(() => {
    return <ShowVideoInfo open={videoopen} messageid={initMessageid} handleClose={handleVideoClose} />;
  }, [videoopen, initMessageid]);

  const [openReplyid, setOpenReplyid] = useState(0);
  const [openReply, setOpenReply] = useState(false);
  const handleReplyClose = () => {
    setOpenReply(false);
  };

  const [currentMessageid, setCurrentMessageid] = useState(0);

  const handleEditClose = () => {
    setOpenEditMessage(false);
  };

  const handleGetEditClose = () => {
    setOpenEdit(false);
  };

  const [openQuestion, setOpenQuestion] = useState({ open: false, messageid: null, questionid: null });
  const handleQuestionClose = () => {
    setOpenQuestion({ ...openQuestion, open: false });
  };

  const [openAnpi, setOpenAnpi] = useState(false);
  const handleAnpiVoteClose = () => {
    setOpenAnpi(false);
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openAnpi}
        honbuid={state.honbuid}
        roomid={state.currentRoomid}
        //openAnpiId={selectedAnpiid}
        handleClose={handleAnpiVoteClose}
        afterProc={() => {}}
        isEnabled={true}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openAnpi, state.currentRoomid]);
  const [openCommon, setOpenCommon] = useState(false);
  const handleCommonClose = () => {
    setOpenCommon(false);
  };

  const delmessage = async (messageid) => {
    var url = API_URL + `/delMessage?roomid=${state.currentRoomid}&messageid=${messageid}`;
    //console.log("urll", url);
    const result = await custom_axios(url);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };

  const callback = async (entries) => {
    for (const e of entries) {
      if (e.isIntersecting) {
        // 最上部のメッセージ表示
        const filterd = localChat.filter(
          (f) => f.messageid == e.target.id && f.messageid == localChat[0].messageid && f.firstmessage == "0"
        );
        if (filterd.length > 0 && messageLoading.current === false) {
          messageLoading.current = true;
          const lastMessageid = filterd[0].messageid;
          myRefMessageid.current = lastMessageid;
          //oldScrollHeight.current = node.current.scrollHeight - node.current.offsetHeight;

          //console.log("IntersectionObserver", "setLoading(true)");

          const honbuid = localStorage.getItem("honbuid");

          const sqlstr =
            API_URL +
            `/GetAdditionalConversationList?roomid=${state.currentRoomid}&honbuid=${honbuid}&messageid=${lastMessageid}`;

          const result = await axios(sqlstr);
          const status = result.status;
          //console.log("get結果", result);
          if (status === 200) {
            //node.current.scrollTop = 1;
            dispatch({
              type: "ADD_CONVERSASTION",
              payload: result.data.conversations,
            });
          }
          //setLoading(false);
          messageLoading.current = false;
        }

        // 最新メッセージ表示
        const receiveLocalChat = localChat.filter((ele) => ele.honbuid !== me);
        const mostNewReceiveMessage = receiveLocalChat[receiveLocalChat.length - 1];
        const mostNewMessage = localChat[localChat.length - 1];
        const filterd2 = localChat.filter(
          (f) => f.messageid == e.target.id && f.messageid == mostNewMessage.messageid && f.firstmessage !== "1"
        );
        if (filterd2.length > 0 && messageLoading.current === false) {
          setIsScrollBottom(true);
          // 既読にする
          setUnReadCnt(0);
          const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));
          const proc = async () => {
            /* 既読の送信が早すぎると空ぶるかもしれないので、ここで0.5秒待ち */
            await sleep(500);
            hubConnection.invoke("ReadMessage", mostNewReceiveMessage.roomid, me);
          };
          if (
            receiveLocalChat.length > 0 &&
            mostNewReceiveMessage.unreaded &&
            mostNewReceiveMessage.unreaded === "1" &&
            mostNewReceiveMessage.honbuid !== me
          ) {
            dispatch({ type: "LOCAL_READMESSAGE", payload: {} });
            proc();
          }
        } else {
          setIsScrollBottom(false);
        }
      }
    }
  };

  // 部屋を開いた時
  useEffect(() => {
    //rootRef.current.scrollTop = 100;
    //rootRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    //window.scrollTo(0, rootRef.current.offsetTop);
    /* 初回、部屋を開けた時は、一番下にスクロールするためのフラグフラグは本当は使いたくないが、現状はこれしかない */
    setFirstOpen(true);
    messageLoading.current = false;

    /* アクティブになったら、既読数を最新にする（ブラウザは既読が取得失敗する場合があるので） */
    const getTimeOfRoom = async () => {
      const result = await custom_axios(API_URL + `/getTimeOfRoom?roomid=${localStorage.getItem("currentRoomid")}`);
      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        dispatch({
          type: "UPDATE_READMESSAGE",
          payload: {
            ...result.data,
          },
        });
      }
    };
    if (localStorage.getItem("currentRoomid") != null && localStorage.getItem("currentRoomid") != "") {
      getTimeOfRoom();
    }

    //return () => console.log("displist MessageList", "end");
  }, [state.currentRoomid]);

  const MemoNewQueVote = useMemo(() => {
    return (
      <ShowNewQueVote
        open={openQuestion.open}
        honbuid={state.honbuid}
        openAnpiId={openQuestion.questionid}
        handleClose={handleQuestionClose}
        afterProc={() => {}}
        messageid={openQuestion.messageid}
      />
    );
  }, [openQuestion.open]);

  const handleReadClose = () => {
    setOpenRead({ ...openRead, open: false });
  };
  // const handleReadClose = useCallback(() => {
  //   setOpenRead({ ...openRead, open: false });
  // }, []);
  const MemoReadMember = useMemo(() => {
    // console.log("MemoReadMember", "openRead", openRead);
    // console.log("MemoReadMember", "currentMessageid", currentMessageid);
    // console.log("MemoReadMember", "state.currentRoomid", state.currentRoomid);
    return (
      <ShowReadMember
        open={openRead}
        handleClose={handleReadClose}
        messageid={currentMessageid}
        roomid={state.currentRoomid}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openRead, currentMessageid, state.currentRoomid]);

  const MemoShowGetEditMessage = useMemo(() => {
    return <ShowGetEditMessage open={openEdit} handleClose={handleGetEditClose} messageid={currentMessageid} />;
  }, [openEdit, currentMessageid]);

  // const MemoShowDoc = useMemo(() => {
  //   return <ShowDoc open={docurl.open} handleClose={handleDocClose} docurl={docurl.url} />;
  // }, [docurl]);

  const MemoShowEditMessage = useMemo(() => {
    return (
      <ShowEditMessage
        open={openEditMessage}
        handleClose={handleEditClose}
        messageid={initMessageid}
        message={editMessage.message}
      />
    );
  }, [openEditMessage, initMessageid, editMessage]);

  const MemoCommon = useMemo(() => {
    return (
      <ShowCommon
        open={openCommon}
        handleClose={handleCommonClose}
        title="削除しますか？"
        handleCallBack={() => {
          delmessage(initMessageid);
          handleCommonClose();
        }}
      ></ShowCommon>
    );
  }, [openCommon, initMessageid]);

  const MemoReply = useMemo(() => {
    return (
      <ShowReply
        open={openReply}
        handleClose={handleReplyClose}
        roomid={state.currentRoomid}
        honbuid={state.honbuid}
        replyid={openReplyid}
        setInitMessageid={setInitMessageid}
        setImgOpen={setImgOpen}
        setVideoOpen={setVideoOpen}
      />
    );
  }, [openReply, openReplyid]);
  const [snackopen, setSnackOpen] = React.useState(false);
  const MemoSnackBar = useMemo(() => {
    return (
      <Snackbar
        open={snackopen}
        autoHideDuration={3000}
        onClose={() => {
          setSnackOpen(false);
        }}
        message="スタンプを貰いました。"
      />
    );
  }, [snackopen]);

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handleMenuView3 = (e) => {
    setAnchorEl3(e.currentTarget);
  };
  const handleMenuClose3 = () => {
    setAnchorEl3(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenuView = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handleMenuView2 = (e) => {
    setAnchorEl2(e.currentTarget);
  };
  const handleMenuClose2 = () => {
    setAnchorEl2(null);
  };

  const { hubConnection } = useContext(Store);
  // const sendGood = () => {
  //   hubConnection.invoke("SendGood", state.currentRoomid, initMessageid, localStorage.getItem("honbuid"));
  // };

  const sendGoodEmoji = (emojikbn) => {
    hubConnection.invoke(
      "SendGoodEmoji",
      state.currentRoomid,
      initMessageid,
      localStorage.getItem("honbuid"),
      emojikbn
    );
  };

  const getStamp = async () => {
    const honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/updateStamp?stampid=${editMessage.message}&honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      dispatch({
        type: "ADD_STAMP",
        payload: result.data.stampid,
      });
      setSnackOpen(true);
    }
  };

  const searchHpName = (honbuid) => {
    let croom = state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].memberlist;
    let honbuids = croom.filter((f) => f.honbuid === honbuid);
    if (honbuids.length > 0) {
      let hpname = croom.filter((f) => f.honbuid === honbuid)[0].hpname;
      return hpname;
    } else {
      return "";
    }
  };

  return (
    <div className="messages-area" ref={rootRef}>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={(e) => {
            handleMenuView3(e);
            handleMenuClose();
          }}
        >
          反応する
        </MenuItem>
        <MenuItem
          onClick={() => {
            //setInitMessageid(conversation.messageid);
            setOpenCommon(true);
            handleMenuClose();
          }}
        >
          削除する
        </MenuItem>
        {["replymessage", "message"].includes(editMessage.messagetype) && (
          <MenuItem
            onClick={() => {
              //setInitMessageid(conversation.messageid);
              setOpenEditMessage(true);
              handleMenuClose();
            }}
          >
            修正する
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setIsReply(initMessageid);
            handleMenuClose();
          }}
        >
          返信する
        </MenuItem>
      </Menu>

      <Menu id="simple-menu" anchorEl={anchorEl2} keepMounted open={Boolean(anchorEl2)} onClose={handleMenuClose2}>
        <MenuItem
          onClick={(e) => {
            handleMenuView3(e);
            handleMenuClose2();
          }}
        >
          反応する
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsReply(initMessageid);
            handleMenuClose2();
          }}
        >
          返信する
        </MenuItem>
        {["stamp"].includes(editMessage.messagetype) && (
          <MenuItem
            onClick={() => {
              //setInitMessageid(conversation.messageid);
              getStamp();
              handleMenuClose2();
            }}
          >
            このスタンプを貰う
          </MenuItem>
        )}
      </Menu>

      <Menu id="simple-menu" anchorEl={anchorEl3} keepMounted open={Boolean(anchorEl3)} onClose={handleMenuClose3}>
        <Tooltip title="にっこり">
          <MenuItem
            onClick={() => {
              sendGoodEmoji("emoji1");
              handleMenuClose3();
            }}
          >
            {GOOD_EMOJI[0]}
          </MenuItem>
        </Tooltip>
        <Tooltip title="泣き笑い">
          <MenuItem
            onClick={() => {
              sendGoodEmoji("emoji2");
              handleMenuClose3();
            }}
          >
            {GOOD_EMOJI[1]}
          </MenuItem>
        </Tooltip>
        <Tooltip title="いいね">
          <MenuItem
            onClick={() => {
              sendGoodEmoji("emoji3");
              handleMenuClose3();
            }}
          >
            {GOOD_EMOJI[2]}
          </MenuItem>
        </Tooltip>
        <Tooltip title="ハート">
          <MenuItem
            onClick={() => {
              sendGoodEmoji("emoji4");
              handleMenuClose3();
            }}
          >
            {GOOD_EMOJI[3]}
          </MenuItem>
        </Tooltip>
        <Tooltip title="お辞儀">
          <MenuItem
            onClick={() => {
              sendGoodEmoji("emoji5");
              handleMenuClose3();
            }}
          >
            <StyledOjigimanDiv>
              <img src={ojigiman} height="28" alt="アイコン" />
            </StyledOjigimanDiv>
          </MenuItem>
        </Tooltip>
      </Menu>

      {messageLoading.current && <div>メッセージ読み込み中....</div>}
      {localChat &&
        localChat
          .sort(function (a, b) {
            if (b.messageid == "0") {
              return -1;
            }
            if (a.messageid == "0") {
              return 1;
            }
            if (Number(a.messageid) > Number(b.messageid)) {
              return 1;
            }
            if (Number(a.messageid) < Number(b.messageid)) {
              return -1;
            }
            return 0;
          })
          .map((e) => {
            /* chatisがアクティブでは無い場合、既読にはしないための対応 */
            if (e.unreaded && e.unreaded === "1" && e.honbuid !== me && state.isActive == false) {
              return null;
            } else {
              return (
                <div
                  ref={e.messageid == myRefMessageid.current ? myRef : null}
                  key={e.messageid}
                  className="message-wrapper"
                  id={e.messageid}
                >
                  <Message
                    conversation={e}
                    lastmesasgeid={localChat[localChat.length - 1].messageid}
                    setOpenRead={setOpenRead}
                    setOpenEdit={setOpenEdit}
                    setEditMessage={setEditMessage}
                    //setOpenGood={setOpenGood}
                    setOpenQuestion={setOpenQuestion}
                    setCurrentMessageid={setCurrentMessageid}
                    //setInitQuestionid={setInitQuestionid}
                    setInitMessageid={setInitMessageid}
                    //callback={delmessage}
                    setIsReply={setIsReply}
                    setOpenReply={setOpenReply}
                    setOpenReplyid={setOpenReplyid}
                    setAvatarOpen={setAvatarOpen}
                    setImgOpen={setImgOpen}
                    setVideoOpen={setVideoOpen}
                    setInitHonbuid={setInitHonbuid}
                    // setCallOpen={setCallOpen}
                    setOpenCommon={setOpenCommon}
                    handleMenuView={handleMenuView}
                    handleMenuView2={handleMenuView2}
                    //setOpenAnpi={setOpenAnpi}
                    isScrollBottom={isScrollBottom}
                    isBukai={isBukai}
                    searchHpName={(h) => searchHpName(h)}
                    setBotUrl={setBotUrl}
                    setBotImgOpen={setBotImgOpen}
                    // setDocurl={setDocurl}
                  />
                </div>
              );
            }
          })}

      {/* </InfiniteScroll>
       */}
      {/*<ShowQuestion open={openQuestion} handleClose={handleQuestionClose} messageid={currentMessageid} />*/}
      {MemoNewQueVote}
      {MemoCommon}
      {MemoReadMember}
      {MemoShowGetEditMessage}
      {MemoShowEditMessage}
      {/* {MemoGoodMember} */}
      {MemoReply}
      {MemoAvatar}
      {MemoImage}
      {MemoBotImage}
      {MemoVideo}
      {/* {MemoCall} */}
      {MemoAnpiVote}
      {MemoSnackBar}
      {/* {MemoShowDoc} */}
    </div>
  );
}

export default MessageList;
