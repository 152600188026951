import React, { useReducer, useEffect, useState } from "react";
import reducer from "./reducer";
import PropTypes from "prop-types";
import * as signalR from "@microsoft/signalr";
import { HUB_URL } from "../Common/Common";
import { API_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";

const initialState = {
  addConversationCnt: 0,
  addVoteCnt: 0,
  isnotice: "0",
  isActive: true,
  honbuid: "",
  color: localStorage.getItem("color"),
  currentRoomid: "",
  chatrooms: [],
  chatsFilter: "",
  filteredMessages: [],
  isMiniRoom: false,
  parentroomid: "",
};

const Store = React.createContext();
const hubConnection = new signalR.HubConnectionBuilder()
  .withUrl(HUB_URL + `?honbuid=${localStorage.getItem("honbuid")}`)
  .withAutomaticReconnect()
  .build();

const Provider = ({ children }) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errTime, setErrTime] = useState(null);
  ////console.log("store.js", "useReducer");

  const hubFunction = (roomid, name, message, messagetype, honbuid, fileno, messageid, time, timenum) => {
    if (messagetype == "good") {
      dispatch({ type: "RECEIVE_GOOD", payload: { roomid: roomid, message: message, messageid: messageid } });
    } else if (messagetype == "goodemoji") {
      dispatch({
        type: "RECEIVE_GOOD_EMOJI",
        payload: { roomid: roomid, honbuid: honbuid, message: message, messageid: messageid, emojikbn: fileno },
      });
    } else if (messagetype == "call") {
      dispatch({
        type: "RECEIVE_MESSAGE",
        payload: {
          callroomid: roomid,
          callhonbuid: honbuid,
          callname: name,
          roomid: roomid,
          name: name,
          message: message,
          messagetype: messagetype,
          honbuid: honbuid,
          fileno: fileno,
          messageid: messageid,
          time: time,
          timenum: timenum,
        },
      });
    } else if (messagetype == "delroom") {
      /* 部屋を退室したので、部屋受信窓口を削除 */
      //hubConnection.off(`ReceiveMessage${roomid}`);
      // 部屋削除
      dispatch({ type: "DEL_ROOM", payload: roomid });
    } else if (messagetype == "delmessage") {
      dispatch({ type: "RECEIVE_DELMESSAGE", payload: { messageid: messageid } });
    } else if (messagetype == "roomname") {
      dispatch({ type: "RECEIVE_ROOMNAME", payload: { roomid: roomid, message: message } });
    } else if (messagetype == "roomavatar") {
      dispatch({ type: "RECEIVE_ROOMAVATAR", payload: { fileno: fileno } });
    } else if (messagetype == "commentquestion") {
      dispatch({ type: "RECEIVE_COMMENTQUESTION", payload: {} });
    } else if (messagetype == "votequestion") {
      dispatch({ type: "RECEIVE_VOTEQUESTION", payload: { message: message, honbuid: honbuid } });
    } else if (messagetype == "readmessage") {
      dispatch({ type: "RECEIVE_READMESSAGE", payload: { timenum: timenum, honbuid: honbuid } });
      // } else if (messagetype == "hideroom") {
      //   dispatch({ type: "HIDE_ROOM", payload: { roomid: roomid } });
    } else if (messagetype == "editmessage") {
      dispatch({
        type: "RECEIVE_EDITMESSAGE",
        payload: { roomid: roomid, message: message, messageid: messageid },
      });
    } else if (["video", "botphoto", "photo", "stamp", "message", "file", "createquestion"].includes(messagetype)) {
      dispatch({
        type: "RECEIVE_MESSAGE",
        payload: {
          roomid: roomid,
          name: name,
          message: message,
          messagetype: messagetype,
          honbuid: honbuid,
          fileno: fileno,
          messageid: messageid,
          time: time,
          timenum: timenum,
        },
      });
    } else if (messagetype == "anpi") {
      dispatch({ type: "RECEIVE_ANPI", payload: {} });
    } else {
      fetchData(roomid).then((r) => {
        dispatch({ type: "UPDATE_CONVERSASTION", payload: r });
      });
    }

    //openConversationList(roomid, dispatch);
  };

  const delChatConnection = (roomid) => {
    //console.log("addChatConnection", roomid);
    hubConnection.invoke("removeGroup", roomid);
    //hubConnection.on(`ReceiveMessage${roomid}`, hubFunction);
  };
  const addChatConnection = (roomid) => {
    //console.log("addChatConnection", roomid);
    hubConnection.invoke("addGroup", roomid);
    //hubConnection.on(`ReceiveMessage${roomid}`, hubFunction);
  };
  const fetchData = async (roomid) => {
    let honbuid = localStorage.getItem("honbuid");
    const httpstring =
      roomid == ""
        ? `/getConversationList?honbuid=${honbuid}`
        : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
    const result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      return result.data;
    }
  };

  const fetchAddData = async (roomid) => {
    let honbuid = localStorage.getItem("honbuid");
    const httpstring =
      roomid != ""
        ? `/getConversationList?honbuid=${honbuid}`
        : `/getConversationListOne?honbuid=${honbuid}&roomid=${roomid}`;
    const result = await custom_axios(API_URL + httpstring);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      return result.data;
    }
  };

  const fetchInitData = async () => {
    let honbuid = localStorage.getItem("honbuid");
    const result = await custom_axios(API_URL + `/getConversationList?honbuid=${honbuid}`);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      dispatch({ type: "INIT_CONVERSASTION", payload: { ...result.data, honbuid: honbuid } });
    }

    hubConnection.off(`ReceiveInformation${honbuid}`);
    hubConnection.on(`ReceiveInformation${honbuid}`, (type, roomid) => {
      if (type === "add") {
        /* 部屋が追加されたので、部屋受信窓口を設置 */
        addChatConnection(roomid);
        fetchAddData(roomid).then((r) => {
          dispatch({ type: "UPDATE_CONVERSASTION", payload: r });
        });
      } else if (type === "del") {
        /* 部屋を退室したので、部屋受信窓口を削除 */
        //hubConnection.off(`ReceiveMessage${roomid}`);
        delChatConnection(roomid);
        // 部屋削除
        dispatch({ type: "DEL_ROOM", payload: roomid });

        //notifyListeners();
      }
    });
    /* for (let i = 0; i < Object.keys(hubConnection.methods).length; i++) {
      hubConnection.off(Object.keys(hubConnection.methods)[i]);
    } */
    /* for (var j in result.data.chatrooms) {
      hubConnection.off(`ReceiveMessage${result.data.chatrooms[j].roomid}`);
    } */

    hubConnection.off(`ReceiveMessage`);
    hubConnection.on(`ReceiveMessage`, hubFunction);

    //for (var i in result.data.chatrooms) {
    //hubConnection.off(`ReceiveMessage${result.data.chatrooms[i].roomid}`);
    //addChatConnection(result.data.chatrooms[i].roomid);
    //}
  };

  const startSignalRConnection = (connection) => {
    console.info("connecttest", hubConnection);
    hubConnection.baseUrl = HUB_URL + `?honbuid=${localStorage.getItem("honbuid")}`;
    connection
      .start()
      .then(() => {
        console.info("signalRのスタート処理", "ReStarted!");
        setErrTime(null);
        fetchInitData();
      })
      .catch((err) => {
        console.error("signalRのスタート処理", "Error: ", err);
        setErrTime(Date.now());
      });
    //  }
  };

  useEffect(() => {
    //ここで、signalRをOnしておいて、メッセージがきたらdispatchで、更新してあげる
    // Builds the SignalR connection, mapping it to /chat
    //console.log("hubConnection初期化", hubConnection);
    if (hubConnection.state != signalR.HubConnectionState.Connected) {
      hubConnection
        .start()
        .then(() => console.log("signalR", "Started!"))
        .catch((err) => console.log("signalR", "Error", err));
    }

    hubConnection.onreconnecting(() => {
      console.log("signalR", "reconnecting");
      hubConnection.baseUrl = HUB_URL + `?honbuid=${localStorage.getItem("honbuid")}`;
    });

    hubConnection.onreconnected(() => {
      console.log("signalR", "reconnected");
      fetchData("").then((r) => {
        dispatch({ type: "UPDATE_CONVERSASTION", payload: r });
      });
      //hubConnection.baseUrl = HUB_URL + `?honbuid=${localStorage.getItem("honbuid")}`;
    });

    hubConnection.onclose(() => {
      console.log("signalR", "onClose!!");
      setErrTime(Date.now());
    });
  }, []);

  /* 再接続処理（ネットワーク切断になった場合に、接続しなおすため） */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (errTime != null) startSignalRConnection(hubConnection);
    }, 3000);
    return () => clearTimeout(timer);
  }, [errTime]);

  return <Store.Provider value={{ state, dispatch, hubConnection, hubFunction }}>{children}</Store.Provider>;
};

Provider.propTypes = {
  children: PropTypes.node,
};

export { Store, Provider };
