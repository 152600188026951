import React, { useMemo, useEffect, useContext, useState, useRef, useCallback } from "react";
import Input from "../Input/Input";
import { Store } from "../Store/store";
import MessageList from "../MessageList/MessageList";
import MessageSearchList from "../MessageList/MessageSearchList";
import "./ChatConversations.css";
import ResizePanel from "react-resize-panel";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import ShowReadMember from "../Common/ShowReadMember";
import ShowAnpiVote from "../Common/ShowAnpiVote";

const StyledVoteDiv = styled("div")`
  //cursor: pointer;
  text-align: center;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 10px 10px 10px 10px;
  //box-shadow: grey 0px 7px 16px -4px;
  padding: 10px;
  //margin: 10px;
  width: 100%;
  color: ${(props) => props.theme.palette.primary.contrastText};
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }
`;
const StyledDispTime = styled("div")`
  display: flex;
  align-items: flex-end;
`;
const StyledReadedVoteDiv = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
function ChatConversations() {
  const [isPreSend, setIsPreSend] = useState(false);
  const { state } = useContext(Store);
  const [isSearched, setIsSearched] = useState(false);
  const [isReply, setIsReply] = useState(0);
  const rootRef = useRef(null);
  const [unReadCnt, setUnReadCnt] = useState(0);
  const [fireScrollBottom, setFireScrollBottom] = useState(0);
  const [roominfo, setRoominfo] = useState([]);
  const myRefInput = useRef(null);
  useEffect(() => {
    setIsReply(0);
    setRoominfo(state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0]);
    // //var anpiconversation = [];
    // if (roominfo.isanpi == "1") {
    //   var preanpi = roominfo.conversationlist.filter((f) => f.messagetype == "anpi")[0];
    //   setAnpiconversation(preanpi);
    // } else {
    //   setAnpiconversation([]);
    // }
  }, [state.currentRoomid]);

  const handle = () => {
    setFireScrollBottom(fireScrollBottom + 1);
  };

  const [openRead, setOpenRead] = useState({ open: false, kbn: "anpi" });
  const preventDefaultAnpi = (event) => {
    event.preventDefault();
    setOpenRead({ open: true, kbn: "anpi" });
  };
  // const handleReadClose = () => {
  //   setOpenRead({ ...openRead, open: false });
  // };
  const handleReadClose = useCallback(() => {
    setOpenRead({ ...openRead, open: false });
  }, [openRead]);
  const MemoReadMember = useMemo(() => {
    return (
      <ShowReadMember
        open={openRead}
        handleClose={handleReadClose}
        messageid={0}
        roomid={state.currentRoomid}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openRead]);
  const [openAnpi, setOpenAnpi] = useState(false);
  const handleAnpiVoteClose = () => {
    setOpenAnpi(false);
  };
  const MemoAnpiVote = useMemo(() => {
    return (
      <ShowAnpiVote
        open={openAnpi}
        honbuid={state.honbuid}
        roomid={state.currentRoomid}
        handleClose={handleAnpiVoteClose}
        afterProc={() => {}}
        isEnabled={true}
        isadmin={state.isadmin}
        isanpi={state.isanpi}
      />
    );
  }, [openAnpi, state.currentRoomid]);
  const handleAnpi = () => {
    setOpenAnpi(true);
  };
  return state.chatsFilter != "" ? (
    <MessageSearchList setIsSearched={setIsSearched}></MessageSearchList>
  ) : (
    <div className="conversation">
      {MemoReadMember}
      {MemoAnpiVote}
      {/*<Button onClick={handleClick}>テスト送信</Button>*/}

      {/* <ResizePanel direction="s" style={{ height: "250px" }}> */}
      <div className="resizepaneltop">
        <MessageList
          isSearched={isSearched}
          setIsSearched={setIsSearched}
          rootRef={rootRef}
          isPreSend={isPreSend}
          setIsPreSend={setIsPreSend}
          setIsReply={setIsReply}
          isBukai={state.chatrooms.filter((f) => f.roomid === state.currentRoomid)[0].isbukai}
          //unReadCnt={unReadCnt}
          setUnReadCnt={setUnReadCnt}
          fireScrollBottom={fireScrollBottom}
          //setFireScrollBottom={setFireScrollBottom}
        ></MessageList>
      </div>
      {/* </ResizePanel> */}
      {unReadCnt > 0 ? (
        <Button color="primary" onClick={handle} variant="contained">
          <ExpandMoreIcon />
          新着メッセージあり {unReadCnt}件
        </Button>
      ) : (
        <div></div>
      )}

      {roominfo && roominfo.isanpi == "1" ? (
        <StyledReadedVoteDiv>
          <StyledVoteDiv onClick={handleAnpi}>
            <div>安否確認をする</div>
            <div style={{ fontSize: "80%", marginTop: "5px" }}>(クリックをして開始)</div>
            {/* <div style={{ fontSize: "80%" }}>{anpiconversation.name} が作成</div> */}
          </StyledVoteDiv>
          <StyledDispTime>
            <Link href="#" onClick={preventDefaultAnpi}>
              既読・回答状況
            </Link>
            {/* <div className="ml-10">{anpiconversation.time}</div> */}
          </StyledDispTime>
        </StyledReadedVoteDiv>
      ) : (
        <div></div>
      )}

      {/* {unReadCnt > 0 && (
        <button type="button" color="primary" onClick={handle} variant="extended">
          <ExpandMoreIcon />
          新着メッセージあり
        </button>
      )} */}
      <ResizePanel direction="n" style={{ height: "20vh" }}>
        {/* <ContentEditable
            className="editablediv"
            innerRef={myRef}
            html={inputtext.html}
            disabled={false}
            onChange={(e) => setInputtext({ html: e.target.value })} // handle innerHTML change
          /> */}
        <Input
          // rootRef={rootRef}
          // setIsPreSend={setIsPreSend}
          setIsReply={setIsReply}
          isReply={isReply}
          isMark={roominfo.ismarkme}
          isDirection={roominfo.isdirection}
          myRefInput={myRefInput}
          isBotRoom={roominfo.isbotroom}
        ></Input>
      </ResizePanel>
    </div>
  );
}

export default ChatConversations;
