/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";

function ShowAnpiFilterInputDetail({ initvalue, inputSelectedUnit }) {
  const [unitoptions, setUnitOptions] = useState([]);
  const [selectedunit, setSelectedUnit] = useState([]);
  // const [unitseq, setUnitSeq] = useState("");
  useEffect(() => {
    //初期値を入れる
    // setUnitSeq(initvalue.seq);
    setUnitOptions(
      initvalue.list.map((e) => {
        return { label: e.text, value: e.no };
      })
    );
    setSelectedUnit(
      initvalue.list
        .map((e) => {
          if (e.checked === "true") {
            return { label: e.text, value: e.no };
          } else {
            return;
          }
        })
        .filter((e) => e)
    );
  }, []);

  return (
    <MultiSelect
      options={unitoptions}
      value={selectedunit}
      onChange={(ev) => {
        setSelectedUnit(ev);
        inputSelectedUnit(initvalue.seq, ev);
      }}
      labelledBy={"Select"}
      overrideStrings={{
        selectSomeItems: "選択...",
        allItemsAreSelected: "全選択",
        selectAll: "全選択",
        search: "検索",
        clearSearch: "検索クリア",
      }}
      filterOptions={(options, filter) => {
        if (!filter) {
          return options;
        }
        const re = new RegExp(filter, "i");
        return options.filter(({ label }) => label && label.match(re));
      }}
    />
  );
}

export default ShowAnpiFilterInputDetail;
