/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { API_URL, STATIC_URL, custom_axios, custom_axios_post } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import Search from "@mui/icons-material/Search";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ShowCommon from "../Common/ShowCommon";
import ShowMember from "../Common/ShowMember";
import ShowRoomMember from "../Common/ShowRoomMember";
import ShowRoomAccountAdmin from "../Common/ShowRoomAccountAdmin";
import ShowDirectionSelect from "../Common/ShowDirectionSelect";
import Link from "@mui/material/Link";
import ShowRoomSwitch from "./ShowRoomSwitch";
import { MultiSelect } from "react-multi-select-component";
import Chip from "@mui/material/Chip";

import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  jaJP,
} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
const headCells = [
  { id: "roomid", label: "部屋ID" },
  { id: "roomname", label: "部屋名" },
  { id: "ninzu", label: "メンバー" },
  { id: "yyyymmdd", label: "最終メッセージ日" },
  { id: "isdirection", label: "方向" },
  { id: "isused", label: "有効" },
  { id: "isinformation", label: "自動入退室表示" },
  { id: "ischeck", label: "既読者確認" },
  { id: "iscontact", label: "コンタクト許可" },
];
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const StyledDataGrid = styled("div")`
  height: 100%;
  weight: 100%;
`;
const StyledRapDataGrid = styled("div")`
  height: calc(100vh - 130px);
`;
const StyledCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.palette.primary.light};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12;
  margin-left: -12;
`;

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledRoot = styled("div")`
  width: 100%;
`;
const StyledChip = styled(Chip)`
  margin-right: 5px;
  height: 15px;
  font-size: 8px;
`;
const StyledFlex = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();

  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

function CustomToolbar() {
  //プロパティから utf8WithBom = Turue を渡してあげる。
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

function ShowRoom({ open, handleClose }) {
  const history = useHistory();
  const { state, hubConnection } = useContext(Store);

  const [openRoomName, setOpenRoomName] = useState({ open: false, id: "", name: "", fileno: "", apikey: "" });
  const handleRoomNameClickOpen = () => {
    setOpenRoomName({ ...openRoomName, open: false });
  };

  const [openFa, setOpenFa] = useState(false);
  const handleFaClickOpen = () => {
    setOpenFa(true);
  };
  const handleFaClose = () => {
    setOpenFa(false);
  };

  const [openFaAdd, setOpenFaAdd] = useState(false);
  const handleFaAddClose = () => {
    setOpenFaAdd(false);
  };

  const [roomsList, setRoomsList] = useState([]);
  const [roomsListColumns, setRoomsListColumns] = useState([]);

  const [options, setOptions] = useState([]);
  const [selectedhp, setSelectedHp] = useState([]);
  const [kbnOptions, setKbnOptions] = useState([]);

  const [selectedKbn, setSelectedKbn] = useState([
    { label: "人事連携", value: "1" },
    { label: "グループ", value: "2" },
    // { label: "部会", value: "3" },
  ]);
  const [enableOptions, setEnableOptions] = useState([]);
  const [selectedEnable, setSelectedEnable] = useState([
    { label: "有効", value: "1" },
    // { label: "部会", value: "3" },
  ]);
  //const [memberOptions, setMemberOptions] = useState([]);
  //const [selectedMember, setSelectedMember] = useState([]);

  const [loading, setLoading] = useState(false);
  const initproc = async () => {
    setLoading(true);

    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");

    const body = {
      honbuid: honbuid,
    };
    //console.log("test", body);
    const result = await custom_axios_post(API_URL + "/GetInitHps", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      let localhp = [{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }];
      setOptions(
        result.data.hps.map((e) => {
          return { label: e.hpname, value: e.hpcode };
        })
      );
      setSelectedHp(localhp);
      let localkbn = [];

      if (["1", "6"].includes(state.isadmin)) {
        setKbnOptions([
          { label: "人事連携", value: "1" },
          { label: "グループ", value: "2" },
          { label: "部会", value: "3" },
        ]);
        localkbn = [
          { label: "人事連携", value: "1" },
          { label: "グループ", value: "2" },
        ];
        setSelectedKbn(localkbn);
      } else if (["0"].includes(state.isadmin) && ["1", "2"].includes(state.isbukaiadmin)) {
        setKbnOptions([{ label: "部会", value: "3" }]);
        localkbn = [{ label: "部会", value: "3" }];
        setSelectedKbn(localkbn);
      } else {
        let localoption = [];
        if (["1", "2"].includes(state.isbukaiadmin)) {
          localoption = [
            { label: "人事連携", value: "1" },
            { label: "グループ", value: "2" },
            { label: "部会", value: "3" },
          ];
        } else {
          localoption = [
            { label: "人事連携", value: "1" },
            { label: "グループ", value: "2" },
          ];
        }

        setKbnOptions(localoption);
        localkbn = [
          { label: "人事連携", value: "1" },
          { label: "グループ", value: "2" },
        ];
        setSelectedKbn(localkbn);
      }
      let localenable = [
        { label: "有効", value: "1" },
        { label: "無効", value: "2" },
      ];

      setEnableOptions(localenable);
      setSelectedEnable([{ label: "有効", value: "1" }]);

      setRoomsListColumns(
        headCells.map((v) => {
          if (v.id == "roomid") {
            return {
              field: v.id,
              headerName: v.label,
              width: 70,
            };
          }

          if (v.id == "ninzu") {
            return {
              field: v.id,
              headerName: v.label,
              width: 70,
              valueGetter: (params) => {
                return params.row.ninzu;
              },
              renderCell: (params) => {
                return (
                  <Link href="#" onClick={(e) => preventDefault(e, params.row.roomid)}>
                    {params.row.ninzu}
                  </Link>
                );
              },
            };
          }
          if (v.id == "isdirection") {
            return {
              field: v.id,
              headerName: v.label,
              width: 200,
              valueGetter: (params) => {
                if (params.row.isdirection == "0") {
                  return "双方向";
                }
                if (params.row.isdirection == "1") {
                  return "一方向";
                }
              },
              renderCell: (params) => {
                return (
                  <ShowDirectionSelect
                    initvalue={params.row.isdirection}
                    callBack={handleChangeDirection}
                    roomid={params.row.roomid}
                  />
                );
              },
            };
          }
          if (v.id == "isused") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 70,
              valueGetter: (params) => {
                return params.row.isused;
              },
              renderCell: (params) => {
                return (
                  <ShowRoomSwitch
                    initvalue={params.row.isused}
                    callBack={handleChangeUsed}
                    roomid={params.row.roomid}
                  />
                );
              },
            };
          }

          if (v.id == "isinformation") {
            return {
              field: v.id,
              headerName: v.label,
              width: 130,
              valueGetter: (params) => {
                return params.row.isinformation;
              },
              renderCell: (params) => {
                return (
                  <ShowRoomSwitch
                    initvalue={params.row.isinformation}
                    callBack={handleChangeInformation}
                    roomid={params.row.roomid}
                  />
                );
              },
            };
          }
          if (v.id == "ischeck") {
            return {
              field: v.id,
              headerName: v.label,
              // width: 70,
              valueGetter: (params) => {
                return params.row.ischeck;
              },
              renderCell: (params) => {
                return (
                  <ShowRoomSwitch
                    initvalue={params.row.ischeck}
                    callBack={handleChangeCheck}
                    roomid={params.row.roomid}
                  />
                );
              },
            };
          }
          if (v.id == "iscontact") {
            return {
              field: v.id,
              headerName: v.label,
              width: 130,
              valueGetter: (params) => {
                return params.row.iscontact;
              },
              renderCell: (params) => {
                return (
                  <ShowRoomSwitch
                    initvalue={params.row.iscontact}
                    callBack={handleChangeContact}
                    roomid={params.row.roomid}
                  />
                );
              },
            };
          }
          if (v.id == "yyyymmdd") {
            return {
              field: v.id,
              headerName: v.label,
              width: 200,
            };
          }
          if (v.id == "roomname") {
            return {
              field: v.id,
              headerName: v.label,
              width: 250,
              valueGetter: (params) => {
                return params.row.roomname;
              },
              renderCell: (params) => {
                return (
                  <div className="member-content">
                    <img
                      src={STATIC_URL + `/downloadRoomAvatar?roomid=${params.row.roomid}&fileno=${params.row.fileno}`}
                      width="30"
                      height="30"
                      alt=""
                      className="member-image"
                      onClick={(e) =>
                        openNameAdmin(e, params.row.roomid, params.row.roomname, params.row.fileno, params.row.apikey)
                      }
                      //style={isCurrent ? { border: "1px solid white" } : null}
                    />
                    <StyledFlex>
                      {params.row.isofficial == "1" && (
                        <StyledChip variant="outlined" color="primary" size="small" label="人事連携"></StyledChip>
                      )}
                      {params.row.isbukai == "1" && (
                        <StyledChip variant="outlined" color="primary" size="small" label="部会"></StyledChip>
                      )}
                      <Link
                        href="#"
                        onClick={(e) =>
                          openNameAdmin(e, params.row.roomid, params.row.roomname, params.row.fileno, params.row.apikey)
                        }
                      >
                        {params.row.roomname}
                      </Link>
                    </StyledFlex>
                  </div>
                );
              },
            };
          }
          return { field: v.id, headerName: v.label };
        })
      );
      proc(localhp, localkbn, [{ label: "有効", value: "1" }], "");
    }
  };

  const proc = async (localhps, localkbn, localenable, searchText) => {
    setLoading(true);
    let joinhps = localhps
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinkbn = localkbn
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinenable = localenable
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinkbn.length == 0 || joinenable.length == 0) {
      setRoomsList([]);
      return;
    }
    let honbuid = localStorage.getItem("honbuid");

    const body = {
      honbuid: honbuid,
      hpcodes: joinhps,
      kbn: joinkbn,
      enable: joinenable,
      searchText: searchText,
    };
    const result = await custom_axios_post(API_URL + "/GetRooms", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setRoomsList(
        result.data.rooms.map((v) => {
          return { ...v, id: v.roomid };
        })
      );
    }
  };
  const namechange = (id, name) => {
    setRoomsList(
      roomsList.map((e) => {
        if (e.roomid == id) {
          return { ...e, roomname: name };
        }
        return e;
      })
    );
    setOpenRoomName({ ...openRoomName, name: openRoomName.name });
  };
  const filenochange = (id) => {
    setRoomsList(
      roomsList.map((e) => {
        if (e.roomid == id) {
          return { ...e, fileno: String(Number(e.fileno) + 1) };
        }
        return e;
      })
    );
    setOpenRoomName({ ...openRoomName, fileno: String(Number(openRoomName.fileno) + 1) });
  };
  const afterAddMemberProc = () => {
    setAddMember(addmember + 1);
    proc(selectedhp, selectedKbn, selectedEnable, searchText);
  };
  const afterSaveProc = async () => {
    setLoading(true);
    let joinhps = selectedhp
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinkbn = selectedKbn
      .map((e) => {
        return e.value;
      })
      .join("_");

    let joinenable = selectedEnable
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0 || joinkbn.length == 0) {
      setRoomsList([]);
      return;
    }
    //console.log("selected procの中身", joinhps);
    let honbuid = localStorage.getItem("honbuid");
    const body = {
      honbuid: honbuid,
      hpcodes: joinhps,
      kbn: joinkbn,
      enable: joinenable,
      searchText: searchText,
      //member: joinmember,
    };
    //console.log("test", body);
    const result = await custom_axios_post(API_URL + "/GetRooms", body);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      setLoading(false);
      setRoomsList(result.data.rooms);
    }
  };

  useEffect(() => {
    if (open) {
      initproc();
    }
  }, [open]);

  const handleChangeUsed = async (roomid) => {
    const result = await custom_axios(API_URL + `/changeRoomUsed?roomid=${roomid}`);
    const status = result.status;
    /* 部屋の状態更新 */
    hubConnection.invoke("RoomUpdate", roomid, state.honbuid, "", "", "information");
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeInformation = async (roomid) => {
    const result = await custom_axios(API_URL + `/changeRoomInformation?roomid=${roomid}`);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeCheck = async (roomid) => {
    const result = await custom_axios(API_URL + `/changeRoomCheck?roomid=${roomid}`);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeContact = async (roomid) => {
    const result = await custom_axios(API_URL + `/changeRoomContact?roomid=${roomid}`);
    const status = result.status;

    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const handleChangeDirection = async (roomid, value) => {
    const result = await custom_axios(API_URL + `/changeDirection?roomid=${roomid}&value=${value}`);
    const status = result.status;
    /* 部屋の状態更新 */
    hubConnection.invoke("RoomUpdate", roomid, state.honbuid, "", "", "information");
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const [openMember, setOpenMember] = useState(false);
  const [roomid, setRoomid] = useState("");
  const handleMemberClose = () => {
    setOpenMember(false);
  };
  const preventDefault = (event, roomid) => {
    event.preventDefault();
    setRoomid(roomid);
    setOpenMember(true);
  };
  const openNameAdmin = (event, roomid, roomname, fileno, apikey) => {
    event.preventDefault();
    setOpenRoomName({ open: true, id: roomid, name: roomname, fileno: fileno, apikey: apikey });
  };

  const [openModal, setOpenModal] = useState(false);
  const handleLeaveMemberConfirm = () => {
    setOpenModal(false);
  };
  const [leaveId, setLeaveId] = useState([]);
  const [leaveName, setLeaveName] = useState([]);
  const [leavedId, setLeavedId] = useState([]);
  const [addmember, setAddMember] = useState(0);
  const MemoRoomMember = useMemo(() => {
    return (
      <ShowRoomMember
        open={openMember}
        handleClose={handleMemberClose}
        roomid={roomid}
        setOpenModal={setOpenModal}
        setLeaveId={setLeaveId}
        setLeaveName={setLeaveName}
        leavedId={leavedId}
        setOpenFaAdd={setOpenFaAdd}
        addmember={addmember}
      />
    );
  }, [openMember, roomid, leavedId, addmember]);

  const MemoRoomName = useMemo(() => {
    return (
      <ShowRoomAccountAdmin
        open={openRoomName}
        handleClose={handleRoomNameClickOpen}
        filenochange={filenochange}
        namechange={namechange}
      />
    );
  }, [openRoomName]);

  const LeaveMemberConfirm = useMemo(() => {
    return (
      <ShowCommon
        open={openModal}
        title="退室させますか？"
        handleClose={handleLeaveMemberConfirm}
        handleCallBack={async () => {
          /* 削除処理 */
          var resturl = `/delUserFromRoom?roomid=${roomid}&honbuid=${leaveId}`;
          const result = await custom_axios(API_URL + resturl);
          const status = result.status;
          if (status === 401) {
            history.replace("/loginError");
          }
          //console.log("createRoom", result);
          if (status === 200) {
            hubConnection.invoke(
              "SendMessage",
              roomid,
              leaveId,
              leaveName,
              leaveName + "が退室しました",
              "information"
            );
          }
          setLeavedId(leaveId);
          proc(selectedhp, selectedKbn, selectedEnable, searchText);
          handleLeaveMemberConfirm();
        }}
      ></ShowCommon>
    );
  }, [openModal, leaveId, leaveName]);
  const MemoMember = useMemo(() => {
    return (
      <ShowMember
        open={openFa}
        handleClose={handleFaClose}
        isadduser={false}
        roomid=""
        iswithme={true}
        callback={afterSaveProc}
      />
    );
  }, [openFa]);
  const MemoAddMember = useMemo(() => {
    return (
      <ShowMember
        open={openFaAdd}
        handleClose={handleFaAddClose}
        isadduser={true}
        roomid={roomid}
        iswithme={true}
        callback={afterAddMemberProc}
      />
    );
  }, [openFaAdd]);

  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleKeyDown = (e) => {
    //e.preventDefault();
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      if (searchText != "") {
        handleSearchClick();
      }
    }
  };
  const handleCloseClick = async () => {
    setSearchText("");
    proc(selectedhp, selectedKbn, selectedEnable, "");
  };
  const handleSearchClick = async () => {
    proc(selectedhp, selectedKbn, selectedEnable, searchText);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"xl"}
      TransitionComponent={Transition}
    >
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">部屋一覧</div>
          <div className="question-title-actions">
            <StyledButton
              onClick={() => {
                handleFaClickOpen();
              }}
              variant="outlined"
            >
              部屋作成
            </StyledButton>
            <div style={{ width: "20px" }}></div>
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledRoot>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="multi-select">
                <MultiSelect
                  options={options}
                  value={selectedhp}
                  onChange={(e) => {
                    setSelectedHp(e);
                    proc(e, selectedKbn, selectedEnable, searchText);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
              <div className="multi-select">
                <MultiSelect
                  options={kbnOptions}
                  value={selectedKbn}
                  onChange={(e) => {
                    setSelectedKbn(e);
                    proc(selectedhp, e, selectedEnable, searchText);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>

              <div className="multi-select">
                <MultiSelect
                  options={enableOptions}
                  value={selectedEnable}
                  onChange={(e) => {
                    setSelectedEnable(e);
                    proc(selectedhp, selectedKbn, e, searchText);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>
            <div className="chat-search-admin">
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleSearchClick}>
                  <Search />
                </IconButton>
              </div>
              <input
                placeholder="ユーザー検索"
                value={searchText}
                name="chatsFilter"
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                className="chat-search-field"
              />
              <div className="chat-search-icon">
                <IconButton color="primary" aria-label="send message" onClick={handleCloseClick}>
                  <Close />
                </IconButton>
              </div>
            </div>
          </div>
          {loading ? (
            <StyledRapDataGrid>
              <StyledCircularProgress />
            </StyledRapDataGrid>
          ) : (
            <StyledRapDataGrid>
              <StyledDataGrid>
                <DataGrid
                  getRowId={(row) => row.roomid}
                  hideFooterSelectedRowCount={true}
                  rows={roomsList}
                  columns={roomsListColumns}
                  pagination
                  density={"compact"}
                  // checkboxSelection
                  // disableRowSelectionOnClick
                  // onRowSelectionModelChange={(RowId) => {
                  //   // 選択された行を特定するための処理
                  //   // const selectedRowId = new Set(RowId);
                  //   // const selectedRows = roomsList.filter((dataGridRow) => selectedRowId.has(dataGridRow.id));
                  //   // setSelectionModel(selectedRows);
                  //   console.log("RowId", RowId);
                  // }}
                  slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination,
                  }}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        roomid: false,
                      },
                    },
                  }}
                  localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                ></DataGrid>
              </StyledDataGrid>
            </StyledRapDataGrid>
          )}
        </StyledRoot>
      </StyledDialogContent>
      {MemoRoomMember}
      {MemoMember}
      {LeaveMemberConfirm}
      {MemoAddMember}
      {MemoRoomName}
    </Dialog>
  );
}

export default ShowRoom;
