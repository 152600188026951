/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { STAMP_URL, API_URL, unescapeHTML, custom_axios, custom_axios_post } from "../Common/Common";
// import { useHistory } from "react-router-dom";
// import { Store } from "../Store/store";
import Slide from "@mui/material/Slide";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ContentEditable from "react-contenteditable";
import Send from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import ShowStampPhoto from "../Common/ShowStampPhoto";
import AttachFile from "@mui/icons-material/AttachFile";
import Badge from "@mui/material/Badge";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDropzone } from "react-dropzone";
import Face from "@mui/icons-material/Face";
import ShowCommon from "../Common/ShowCommon";
import Delete from "@mui/icons-material/Delete";
import ShowReservedImageInfo from "../Common/ShowReservedImageInfo";
import ShowReservedVideoInfo from "../Common/ShowReservedVideoInfo";
import ReservedMessage from "../MessageList/ReservedMessage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledDialogContent = styled(DialogContent)`
  padding: 0px;
`;
const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;
const StyledMarginDiv = styled("div")`
  margin-right: 50px;
`;
const StyledRootDiv = styled("div")`
  height: 40vh;
  width: 40vw;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

const StyledFlexDiv = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;

const StyledmbDiv = styled("div")`
  margin-bottom: 10px;
`;

const StyledIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.primary.light};
  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

function ShowReservationCreate({ open, openrs, handleClose, afterProc }) {
  const history = useHistory();
  //   const { state } = useContext(Store);

  const myRef = useRef(null);
  const [inputtext, setInputtext] = useState({ html: "" });
  const [charSize, setCharSize] = useState(0);

  const [localtime, setLocalTime] = useState("");
  const [locallist, setLocallist] = useState([]);

  const InputElRef = useRef(null);
  const inputTemplateRef = useRef(null);
  const [inputTemplateRefFileNum, setInputTemplateRefFileNum] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fileNumError, setFileNumError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ noClick: true });
  const { dispatch } = useContext(Store);
  const { state } = useContext(Store);
  const [openStampModal, setOpenStampModal] = useState(false);
  const deleteStamp = async (ids) => {
    const params = new FormData();
    params.append("honbuid", localStorage.getItem("honbuid"));
    params.append("stampids", ids);
    custom_axios_post(API_URL + "/DeleteStamps", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
      .then((result) => {
        if (result.status === 200) {
          dispatch({ type: "DEL_STAMP", payload: ids });
        }
        //console.log("upload ok...");
      })
      .catch(() => {
        //console.log("upload failed...");
      });
  };

  const handleAddFileClick = async (event) => {
    if (inputTemplateRef.current.files.length === 0) {
      // エクスプローラ開く
      await InputElRef.current.click();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  // InputTemplateRefに入れてるファイルを消す
  const delFilesInInputTemplateRef = () => {
    const dataTransfer = new DataTransfer();
    inputTemplateRef.current.files = dataTransfer.files;
    setInputTemplateRefFileNum(inputTemplateRef.current.files.length);
  };

  // InputTemplateRefにファイルを追加
  const addFilesInInputTemplateRef = (addFiles) => {
    let fileerr = false;
    // 追加する前にファイル数やサイズ確認
    if (inputTemplateRef.current && inputTemplateRef.current.files.length + addFiles.length > 5) {
      /* 添付ファイル数5を超えた場合 */
      setFileNumError(true);
      fileerr = true;
    } else {
      for (let i = 0; i < addFiles.length; i++) {
        if (addFiles[i].size > 20000000) {
          /* 1ファイル20Mを超えた場合 */
          setFileSizeError(true);
          fileerr = true;
        }
      }
    }
    if (!fileerr && addFiles.length !== 0) {
      // https://qiita.com/jkr_2255/items/1c30f7afefe6959506d2
      const dataTransfer = new DataTransfer();
      // すでにあるファイルたち
      [].forEach.call(inputTemplateRef.current.files, (file) => {
        dataTransfer.items.add(file);
      });
      // 追加
      // addFilesが[object FileList]
      if (toString.call(addFiles) === toString.call(inputTemplateRef.current.files)) {
        [].forEach.call(addFiles, (file) => {
          dataTransfer.items.add(file);
        });
      }
      // addFilesが[object Array]
      if (toString.call(addFiles) === toString.call([])) {
        addFiles.forEach((file) => {
          dataTransfer.items.add(file);
        });
      }
      // FileListを上書き
      inputTemplateRef.current.files = dataTransfer.files;
    }
    setInputTemplateRefFileNum(inputTemplateRef.current.files.length);
  };

  // ファイルボタンに紐付いてるのはこの要素（InputElRef）
  // InputTemplateRefに追加する
  const onInputElRefChange = (e) => {
    const files = e.target.files;
    addFilesInInputTemplateRef(files);
  };

  // ドロップファイルをInputTemplateRefに追加
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      addFilesInInputTemplateRef(acceptedFiles);
    }
  }, [acceptedFiles]);

  // ファイル追加ボタン-Menu-ファイル選択をクリック
  const handleAddFileMenuClick = () => {
    setAnchorEl(null);
    InputElRef.current.click();
  };

  // ファイル追加ボタン-Menu-ファイル削除をクリック
  const handleDelFileMenuClick = () => {
    setAnchorEl(null);
    delFilesInInputTemplateRef();
  };

  // ファイルメニューを閉じる
  const handleFileMenuClose = () => {
    setAnchorEl(null);
  };

  // ファイル追加エラー閉じる
  const handleFileErrorClose = () => {
    setFileNumError(false);
    setFileSizeError(false);
  };

  const mainproc = async () => {
    //let honbuid = localStorage.getItem("honbuid");
    if (open == true) {
      if (openrs.time != "") {
        /* 既存データの場合 */
        setLocalTime(openrs.time.replace(" ", "T").replaceAll("/", "-"));
        setLocallist(openrs.conversationlist);
      } else {
        var date1 = new Date();
        date1.setHours(date1.getHours() + 9);
        setLocalTime(date1.toISOString().substring(0, 16));
        setLocallist([]);
      }
    }
  };

  useEffect(() => {
    mainproc();
  }, [open]);

  // メッセージ送信
  const sendServer = async () => {
    /* ファイル送信について: PR121に詳細記載 */
    /* ドロップとボタンで追加されたファイルの準備 */
    // 送信する処理する前にクリアにする（テキストボックスに0.5秒ぐらい残り違和感）
    const messageNodes = [...myRef.current.childNodes];
    //console.log("myRef.current", myRef.current);

    const sendFiles = [];
    [].forEach.call(inputTemplateRef.current.files, (file) => {
      sendFiles.push(file);
    });
    delFilesInInputTemplateRef();
    /* ペーストでの画像の準備 */
    // const sendPastedFiles = [];
    let sendMessage = "";
    for (let i = 0; i < messageNodes.length; i++) {
      const node = messageNodes[i];
      if (node.nodeName === "IMG") {
        // IMGを区切りにtextメッセージを送信する
        if (sendMessage !== "") {
          sendMessage = sendMessage.replace(/<br>/g, "\n");
          await sendText(sendMessage);
          sendMessage = "";
        }
        // Imageを送信する
        const byteString = window.atob(node.src.split(",")[1]);
        const mimeType = node.src.split(";")[0].split(":")[1];
        const content = new Uint8Array(byteString.length);
        for (let j = 0; j < byteString.length; j++) {
          content[j] = byteString.charCodeAt(j);
        }
        const blob = new File([content], "test", {
          type: mimeType,
        });
        // sendPastedFiles.push(blob);
        await sendImage(blob);
      } else if (node.nodeName === "#text") {
        sendMessage += node.data;
      } else if (node.nodeName === "FONT") {
        sendMessage += node.textContent;
      } else if (node.nodeName === "BR") {
        sendMessage += "<br>";
      } else {
        // IMG, #text, BR以外は何もしない
      }
    }

    if (sendMessage !== "") {
      sendMessage = sendMessage.replace(/<br>/g, "\n");
      await sendText(sendMessage);
      sendMessage = "";
    }
    /* 添付ファイルでの画像送信 */
    if (sendFiles.length > 0) {
      for (let i = 0; i < sendFiles.length; i++) {
        sendImage(sendFiles[i]);
      }
    }

    setInputtext({ html: "" });
    setCharSize(0);
  };

  const sendText = async (text) => {
    // if (currentConversation == "") {
    //   console.log("ok");
    // }
    /* contentEditable=true をしてると、何故かhooksが取得できないのでローカルストレージから取得 */
    const sendRoomid = localStorage.getItem("currentRoomid");
    /* テキスト送信 */
    if (text.trim() != "") {
      const params = new FormData();
      params.append("reservationid", openrs.reservationid);
      params.append("roomid", sendRoomid);
      params.append("honbuid", state.honbuid);
      params.append("name", state.user_name);
      params.append("message", unescapeHTML(text.substring(0, 1000).trim()));
      params.append("messagetype", "message");
      const result = await custom_axios_post(API_URL + "/ReserveMessage", params, {
        headers: {
          "content-type": "multipart/form-data",
        },
      });

      const status = result.status;
      if (status === 401) {
        history.replace("/loginError");
      }
      if (status === 200) {
        const conversation = {
          messageid: result.data.messageid,
          honbuid: state.honbuid,
          message: unescapeHTML(text.substring(0, 1000).trim()),
          messagetype: "message",
          isunreaded: false,
          time: "",
          timenum: "",
          gooded: false,
          goodcnt: 0,
        };
        setLocallist([...locallist, conversation]);
      }
    }
  };

  const filetype = ["image/gif", "image/jpeg", "image/png", "image/bmp"];
  const videofiletype = ["video/mp4"];
  // 画像送信
  const sendImage = async (file) => {
    let sendURL = "";
    let sendMessage = "ファイルを送信しました";
    let messagetyle = "";
    if (filetype.includes(file.type)) {
      sendURL = "/uploadReservedImage";
      messagetyle = "photo";
    } else if (videofiletype.includes(file.type)) {
      sendURL = "/uploadReservedVideo";

      if (file.size >= 1000000) {
        sendMessage = String(Math.round(file.size / 1000000)) + "MB";
      } else {
        sendMessage = String(Math.round(file.size / 1000)) + "KB";
      }
      messagetyle = "video";
    } else {
      sendURL = "/uploadReservedFile";
      messagetyle = "file";
    }
    const params = new FormData();
    params.append("reservationid", openrs.reservationid);
    params.append("roomid", localStorage.getItem("currentRoomid"));
    params.append("honbuid", localStorage.getItem("honbuid"));
    params.append("name", state.user_name);
    params.append("message", sendMessage);
    params.append("file", file);
    params.append("filename", file.name);
    params.append("mimetype", file.type);
    const result = await custom_axios_post(API_URL + sendURL, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      const conversation = {
        messageid: result.data.messageid,
        honbuid: state.honbuid,
        message: messagetyle == "file" ? file.name : sendMessage,
        messagetype: messagetyle,
        isunreaded: false,
        time: "",
        timenum: "",
        gooded: false,
        goodcnt: 0,
      };
      setLocallist([...locallist, conversation]);
    }
  };
  // 送信ボタンクリック
  const handleSendBtnClick = (e) => {
    e.preventDefault();
    sendServer();
  };

  const handleKeyDown = (e) => {
    let checked = localStorage.getItem("checkedEnter") == "1" ? true : false;
    const sendMes = () => {
      e.preventDefault();
      sendServer();
    };
    // Enter押下
    if (e.which === 13) {
      // Ctrl
      if (e.ctrlKey && !e.shiftKey) {
        sendMes();
        // Shift
      } else if (!e.ctrlKey && e.shiftKey) {
        // 改行
        return false;
        // Ctrl, Shiftなし
      } else {
        // 「Enterで送信」チェックの有無
        if (checked) {
          sendMes();
        } else {
          // 改行
          return false;
        }
      }
    }
  };

  const [mouseOverId, setMouseOverId] = useState(null);

  // スタンプ閉じる
  const handleStampClose = () => {
    setStampAnchorEl(null);
  };
  const choseStamp = () => {
    setOpenStamp(true);
  };

  const [openStamp, setOpenStamp] = useState(false);
  const handleOpenStampClose = () => {
    setOpenStamp(false);
  };
  const MemoStamp = useMemo(() => {
    return <ShowStampPhoto open={openStamp} handleClose={handleOpenStampClose}></ShowStampPhoto>;
  }, [openStamp]);

  const [stampAnchorEl, setStampAnchorEl] = useState(null);

  // スタンプボタンクリック
  const handleStampClick = (event) => {
    setStampAnchorEl(event.currentTarget);
  };
  // スタンプ送信
  const sendStamp = async (stamp) => {
    //hubConnection.invoke("SendMessage", state.currentRoomid, state.honbuid, state.user_name, stamp, "stamp");

    const params = new FormData();
    params.append("reservationid", openrs.reservationid);
    params.append("roomid", state.currentRoomid);
    params.append("honbuid", state.honbuid);
    params.append("name", state.user_name);
    params.append("message", stamp);
    params.append("messagetype", "stamp");
    const result = await custom_axios_post(API_URL + "/ReserveMessage", params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });

    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    if (status === 200) {
      const conversation = {
        messageid: result.data.messageid,
        honbuid: state.honbuid,
        message: stamp,
        messagetype: "stamp",
        isunreaded: false,
        time: "",
        timenum: "",
        gooded: false,
        goodcnt: 0,
      };
      setLocallist([...locallist, conversation]);
      setStampAnchorEl(null);
    }
  };
  const [imgopen, setImgOpen] = React.useState(false);
  const [videoopen, setVideoOpen] = React.useState(false);
  const [initMessageid, setInitMessageid] = useState(null);
  const handleImgClose = () => {
    setImgOpen(false);
  };
  const handleVideoClose = () => {
    setVideoOpen(false);
  };
  const MemoImage = useMemo(() => {
    return <ShowReservedImageInfo open={imgopen} messageid={initMessageid} handleClose={handleImgClose} />;
  }, [imgopen, initMessageid]);
  const MemoVideo = useMemo(() => {
    return <ShowReservedVideoInfo open={videoopen} messageid={initMessageid} handleClose={handleVideoClose} />;
  }, [videoopen, initMessageid]);

  const [anchordelEl, setAnchordelEl] = useState(null);
  const handleMenuView = (e) => {
    setAnchordelEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchordelEl(null);
  };
  const [openCommon, setOpenCommon] = useState(false);
  const handleCommonClose = () => {
    setOpenCommon(false);
  };
  const delmessage = async (messageid) => {
    setLocallist(locallist.filter((f) => f.messageid != messageid));

    var url = API_URL + `/delReservedMessage?roomid=${state.currentRoomid}&messageid=${messageid}`;
    //console.log("urll", url);
    const result = await custom_axios(url);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
  };
  const MemoCommon = useMemo(() => {
    return (
      <ShowCommon
        open={openCommon}
        handleClose={handleCommonClose}
        title="削除しますか？"
        handleCallBack={() => {
          delmessage(initMessageid);
          handleCommonClose();
        }}
      ></ShowCommon>
    );
  }, [openCommon, initMessageid]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"md"} TransitionComponent={Transition}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <StyledMarginDiv>予約メッセージ作成</StyledMarginDiv>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Menu id="simple-menu" anchorEl={anchordelEl} keepMounted open={Boolean(anchordelEl)} onClose={handleMenuClose}>
          <MenuItem
            onClick={() => {
              //setInitMessageid(conversation.messageid);
              setOpenCommon(true);
              handleMenuClose();
            }}
          >
            削除する
          </MenuItem>
        </Menu>
        {MemoCommon}
        {MemoImage}
        {MemoVideo}
        <StyledFlexDiv>
          <form noValidate>
            <TextField
              id="datetime-local"
              label="送信日時"
              type="datetime-local"
              defaultValue={localtime}
              onChange={(e) => {
                setLocalTime(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </StyledFlexDiv>
        <StyledRootDiv>
          {locallist &&
            locallist
              .sort(function (a, b) {
                if (b.messageid == "0") {
                  return -1;
                }
                if (a.messageid == "0") {
                  return 1;
                }
                if (Number(a.messageid) > Number(b.messageid)) {
                  return 1;
                }
                if (Number(a.messageid) < Number(b.messageid)) {
                  return -1;
                }
                return 0;
              })
              .map((e) => {
                return (
                  <StyledmbDiv key={e.messageid}>
                    <ReservedMessage
                      conversation={e}
                      setInitMessageid={setInitMessageid}
                      setImgOpen={setImgOpen}
                      setVideoOpen={setVideoOpen}
                      handleMenuView={handleMenuView}
                    ></ReservedMessage>
                  </StyledmbDiv>
                );
              })}
        </StyledRootDiv>
        {fileSizeError || fileNumError ? (
          <div className="size-over-background">
            <div onClick={handleFileErrorClose} style={{ cursor: "pointer" }}>
              {fileNumError && "1送信5ファイルまでです!!"}
              {fileSizeError && "1ファイル20MBまでです!!"}
            </div>
          </div>
        ) : (
          <div {...getRootProps({ className: "message-box-wraper" })}>
            <input {...getInputProps()} />
            <ContentEditable
              translate="no"
              onPaste={(e) => {
                let imgs = [];
                for (var i = 0; i < e.clipboardData.items.length; i++) {
                  // Skip content if not image
                  if (e.clipboardData.items[i].type.indexOf("image") == -1) continue;
                  // Retrieve image on clipboard as blob
                  var blob = e.clipboardData.items[i].getAsFile();
                  imgs.push(blob);
                }
                if (imgs.length > 0) {
                  /* 画像データ貼り付け時 */
                  //console.log("inputjs past元", blob);
                  //document.execCommand("insertHTML", false, e.clipboardData);
                } else {
                  /* テキスト貼り付け時 */
                  e.preventDefault();
                  var text = e.clipboardData.getData("text/plain");
                  //console.log("inputjs past加工", text);
                  document.execCommand("insertHTML", false, text.replace(/\r?\n/g, "<br>"));
                }
              }}
              className={"editablediv"}
              innerRef={myRef}
              html={inputtext.html}
              disabled={false}
              onChange={(e) => {
                let sendstr = myRef.current.innerHTML.replace(/<br>/g, "\n").replace(/<(?:.|)*?>/gm, "");
                //console.log("文字の中身", myRef.current.innerHTML, sendstr);

                setCharSize(sendstr.length);
                setInputtext({ html: e.target.value });
              }} // handle innerHTML change
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
        )}
        <div className="sending-area-buttom">
          <div className="reservation-area-buttons">
            {MemoStamp}

            <IconButton color="primary" aria-label="send message" onClick={handleAddFileClick}>
              <AttachFile />
              <Badge
                overlap="rectangular"
                color="primary"
                badgeContent={inputTemplateRefFileNum}
                invisible={inputTemplateRefFileNum === 0}
              ></Badge>
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleFileMenuClose}
            >
              <MenuItem onClick={handleAddFileMenuClick}>ファイル選択</MenuItem>
              {inputTemplateRef.current !== null && inputTemplateRef.current.files.length > 0 && (
                <MenuItem onClick={handleDelFileMenuClick}>ファイル削除</MenuItem>
              )}
            </Menu>
            <input
              ref={InputElRef}
              className="input-single-file"
              id="icon-button-file"
              type="file"
              onChange={onInputElRefChange}
              multiple="multiple"
            />
            <input
              ref={inputTemplateRef}
              className="input-single-file"
              id="input-templete"
              type="file"
              multiple="multiple"
            />

            <IconButton color="primary" aria-label="send message" onClick={handleStampClick}>
              <Face />
            </IconButton>

            <ShowCommon
              open={openStampModal}
              title="削除しますか？"
              handleClose={() => {
                setOpenStampModal(false);
              }}
              handleCallBack={() => {
                deleteStamp(mouseOverId);
                setOpenStampModal(false);
              }}
            ></ShowCommon>
            <Menu
              id="simple-menu"
              anchorEl={stampAnchorEl}
              keepMounted
              open={Boolean(stampAnchorEl)}
              onClose={handleStampClose}
            >
              <div className="stamp-box-wrap">
                <div className="stamp-box">
                  {state.stamps.map((stamp) => {
                    return (
                      <div
                        key={stamp.stampid}
                        className="stamp-box-mouseover"
                        onMouseOver={() => setMouseOverId(stamp.stampid)}
                      >
                        <img
                          src={API_URL + `/getstamp?stampid=${stamp.stampid}`}
                          width="120"
                          height="120"
                          alt=""
                          className="stamp-box-child"
                          onClick={() => sendStamp(stamp.stampid)}
                        />
                        <div className="stamp-box-mouseover-child">
                          {mouseOverId == null || mouseOverId != stamp.stampid ? (
                            <div></div>
                          ) : (
                            <StyledIconButton aria-label="send message" onClick={() => setOpenStampModal(true)}>
                              <Delete />
                            </StyledIconButton>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="stamp-box-plus">
                    <img
                      src={STAMP_URL + `/plus.png`}
                      width="70"
                      height="70"
                      alt=""
                      className="stamp-box-plus-child"
                      onClick={() => choseStamp()}
                    />
                  </div>
                </div>
              </div>
            </Menu>
          </div>
          <div className="reservation-area-buttons">(文字:{charSize} / 1000)</div>
          <div className="reservation-area-buttons">
            <IconButton
              disabled={fileSizeError || fileNumError}
              color="primary"
              aria-label="send message"
              onClick={handleSendBtnClick}
            >
              <Send />
            </IconButton>
          </div>
        </div>
        <StyledFlexDiv>
          <Button
            disabled={locallist && locallist.length > 0 ? false : true}
            style={{ margin: "0 auto" }}
            variant="contained"
            color="primary"
            onClick={async () => {
              const body = {
                reservationid: openrs.reservationid,
                time: localtime.replace("T", " "),
              };

              const result = await custom_axios_post(API_URL + "/createReservation", body);
              if (result.status === 401) {
                history.replace("/loginError");
              }
              afterProc();
            }}
            startIcon={<MailOutlineIcon />}
          >
            保存
          </Button>
        </StyledFlexDiv>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowReservationCreate;
