/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useEffect, useState, useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { API_URL, custom_axios } from "../Common/Common";
import { useHistory } from "react-router-dom";
import { Store } from "../Store/store";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Search from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { MultiSelect } from "react-multi-select-component";
import Skeleton from "@mui/material/Skeleton";
// import Slide from "@mui/material/Slide";

import styled from "@emotion/styled";

const headCells = [
  { id: "time", label: "時刻" },
  { id: "hpname", label: "病院名" },
  { id: "unitname", label: "部署名" },
  { id: "honbuid", label: "本部ID" },
  { id: "name", label: "名前" },
  { id: "description", label: "説明" },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            padding="normal"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <StyledSpan>{order === "desc" ? "sorted descending" : "sorted ascending"}</StyledSpan>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function LoadingTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding="normal">
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const dateFormat = {
  _fmt: {
    yyyy: function (date) {
      return date.getFullYear() + "";
    },
    MM: function (date) {
      return ("0" + (date.getMonth() + 1)).slice(-2);
    },
    dd: function (date) {
      return ("0" + date.getDate()).slice(-2);
    },
    hh: function (date) {
      return ("0" + date.getHours()).slice(-2);
    },
    mm: function (date) {
      return ("0" + date.getMinutes()).slice(-2);
    },
    ss: function (date) {
      return ("0" + date.getSeconds()).slice(-2);
    },
  },
  _priority: ["yyyy", "MM", "dd", "hh", "mm", "ss"],
  format: function (date, format) {
    return this._priority.reduce((res, fmt) => res.replace(fmt, this._fmt[fmt](date)), format);
  },
};

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;

const StyledDialogContent = styled(DialogContent)`
  height: 70vh;
  padding: 0px;
`;
const StyledSaveButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDiv = styled("div")`
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: ${(props) => props.theme.spacing(2) + "px"};
`;
const StyledTable = styled(Table)`
  min-width: 750;
`;
const StyledSpan = styled("span")`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
`;

function ShowJinji({ open, handleClose, options }) {
  const history = useHistory();
  const { state } = useContext(Store);

  const [filesList, setFilesList] = useState([]);
  const [filesListorg, setFilesListOrg] = useState([]);

  const getLastMonth = () => {
    var d = new Date();
    d.setMonth(d.getMonth() - 1);
    return d;
  };

  const [selectedstart, setSelectedStart] = useState(getLastMonth);
  const [selectedend, setSelectedEnd] = useState(new Date());

  const [selectedHp, setSelectedHp] = useState([]);

  const [sword, setSword] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const proc = async () => {
    setLoading(true);
    let joinhps = selectedHp
      .map((e) => {
        return e.value;
      })
      .join("_");

    if (joinhps.length == 0) {
      setFilesList([]);
      setFilesListOrg([]);
      return;
    }

    const reqapi =
      API_URL +
      `/GetJinjiLog?hpcode=${joinhps}&start=${dateFormat.format(selectedstart, "yyyy/MM/dd")}&end=${dateFormat.format(
        selectedend,
        "yyyy/MM/dd"
      )}`;
    //console.log("TEST", reqapi);
    const result = await custom_axios(reqapi);
    const status = result.status;
    if (status === 401) {
      history.replace("/loginError");
    }
    setLoading(false);
    if (status === 200) {
      setFilesList(result.data.jinjilist);
      setFilesListOrg(result.data.jinjilist);
    } else {
      setFilesList([]);
      setFilesListOrg([]);
    }
  };

  useEffect(() => {
    if (sword != "") {
      const updateList = filesListorg.filter(
        (item) =>
          String(item.description).indexOf(sword) !== -1 ||
          String(item.name).indexOf(sword) !== -1 ||
          String(item.unitname).indexOf(sword) !== -1 ||
          String(item.honbuid).indexOf(sword) !== -1
      );
      setFilesList(updateList);
    } else {
      setFilesList(filesListorg);
    }
  }, [sword]);

  useEffect(() => {
    setSelectedHp([{ label: state.hpcode + ":" + state.hpname, value: state.hpcode }]);
    setInitialized(true);
  }, [open]);
  useEffect(() => {
    if (open && initialized) {
      proc();
    }
  }, [open, selectedHp, selectedend, selectedstart, initialized]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">人事連携ログ</div>
          <div className="question-title-actions">
            <div className="multi-select">
              <div className="show-chat-search">
                <div className="show-chat-search-icon">
                  <Search />
                </div>
                <input
                  placeholder="検索"
                  //value={chatsFilter}
                  name="chatsFilter"
                  onChange={(e) => {
                    setSword(e.target.value);
                  }}
                  className="file-search-field"
                />
              </div>
            </div>
            <StyledSaveButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledSaveButton>
          </div>
        </div>
      </StyledDialogTitle>
      <StyledDialogContent>
        <StyledDiv>
          <div className="user-select-area">
            <div className="user-select-subarea">
              <div className="date-select">期間:</div>
              <div className="date-select">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedstart}
                    onChange={(e) => {
                      setSelectedStart(e);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </div>
              <div className="date-select">～</div>
              <div className="date-select">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    okLabel="決定"
                    cancelLabel="キャンセル"
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={selectedend}
                    onChange={(e) => {
                      setSelectedEnd(e);
                    }}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
              </div>

              <div className="multi-select">
                <MultiSelect
                  options={options}
                  value={selectedHp}
                  onChange={(e) => {
                    setSelectedHp(e);
                  }}
                  labelledBy={"Select"}
                  overrideStrings={{
                    selectSomeItems: "選択...",
                    allItemsAreSelected: "全選択",
                    selectAll: "全選択",
                    search: "検索",
                    clearSearch: "検索クリア",
                  }}
                  filterOptions={(options, filter) => {
                    if (!filter) {
                      return options;
                    }
                    const re = new RegExp(filter, "i");
                    return options.filter(({ label }) => label && label.match(re));
                  }}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <div>
              <StyledPaper>
                <TableContainer>
                  <StyledTable
                    //className={classes.table}
                    aria-labelledby="tableTitle"
                    //size={dense ? "small" : "medium"}
                    size="small"
                    aria-label="enhanced table"
                  >
                    <LoadingTableHead />
                    <TableBody>
                      {[...Array(15)].map((_, i) => {
                        const skeletonWidth = 60;
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            <TableCell component="th">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                            <TableCell align="left">
                              <Skeleton animation="wave" variant="rect" width={skeletonWidth} />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </StyledTable>
                </TableContainer>
              </StyledPaper>
            </div>
          ) : (
            <StyledPaper>
              <TableContainer>
                <StyledTable
                  aria-labelledby="tableTitle"
                  //size={dense ? "small" : "medium"}
                  size="small"
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    //classes={classes}
                    //numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    //onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    //rowCount={membersList.length}
                  />
                  <TableBody>
                    {stableSort(filesList, getComparator(order, orderBy)).map((row, index) => {
                      return (
                        <TableRow
                          hover
                          //onClick={(event) => handleClick(event, row.honbuid)}
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">{row.time}</TableCell>
                          <TableCell align="left">{row.hpname}</TableCell>
                          <TableCell align="left">{row.unitname}</TableCell>
                          <TableCell align="left">{row.honbuid}</TableCell>
                          <TableCell align="left">{row.name}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </StyledTable>
              </TableContainer>
            </StyledPaper>
          )}
        </StyledDiv>
      </StyledDialogContent>
    </Dialog>
  );
}

export default ShowJinji;
