/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react";
import { STATIC_URL, GOOD_EMOJI } from "../Common/Common";
import { Store } from "../Store/store";
import "./MessageList.css";
import StampMessage from "./StampMessage";
import TextMessage from "./TextMessage";
import VideoMessage from "./VideoMessage";
import PhotoMessage from "./PhotoMessage";
import BotPhotoMessage from "./BotPhotoMessage";
import FileMessage from "./FileMessage";
import ReplyMessage from "./ReplyMessage";

import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import QuestionAnswerTwoToneIcon from "@mui/icons-material/QuestionAnswerTwoTone";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import PhoneIcon from "@mui/icons-material/Phone";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ojigiman from "../image/ojigiman.gif";
//import ShowCommon from "../Common/ShowCommon";
import styled from "@emotion/styled";
import { css } from "@mui/material";
//import animationData from "./39679-star-burst.json";
import "video-react/dist/video-react.css"; // import css
//import { RootRef } from "@mui/material";

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  cursor: default;
  &:hover {
    cursor: pointer;
  }
`;
const StyledTableDiv = styled("div")`
  min-width: 250px;
  &:hover {
    cursor: pointer;
  }
`;
const StyledTable = styled("table")`
  width: 100%;
  margin-bottom: 10px;
  border-collapse: separate;
  border: 1px ${(props) => props.theme.palette.primary.main} solid;
  white-space: nowrap;
  border-radius: 10px;
  border-spacing: 1px;
  background-color: #fff;
  overflow: hidden;
`;

const StyledTdHeader = styled("td")`
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center;
  background-color: ${(props) => props.theme.palette.primary.light};
  font-size: medium;
`;

const StyledTdRow1 = styled("td")`
  padding: 5px;
  //font-weight: bold;
  letter-spacing: 0;
  background-color: ${(props) => props.theme.palette.primary.light};
`;

const StyledTdRow2 = styled("td")`
  padding: 5px;
  //font-weight: bold;
  letter-spacing: 0;
`;

const StyledLeftRight = styled("div")`
  ${(props) =>
    props.leftmessage
      ? css`
          border-style: solid;
          border-color: #c7c7d3;
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;
          &::before {
            content: "";
            border: 10px solid transparent;
            border-right-color: #c7c7d3;
            position: absolute;
            left: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            /* border-right-color: rgb(247, 248, 252); */
            border-right-color: white;
            position: absolute;
            top: 38px;
            left: -17px;
          }
        `
      : (props) => css`
          border-style: solid;
          border-color: ${props.theme.palette.primary.main};
          border-width: 3px;
          color: #000000;
          box-shadow: grey -1px 7px 13px -4px;

          &::before {
            content: "";
            border: 10px solid transparent;
            border-left-color: ${props.theme.palette.primary.main};
            position: absolute;
            top: 13px;
            right: -20px;
          }
          &::after {
            content: "";
            border: 10px solid transparent;
            /* border-left-color: rgb(247, 248, 252); */
            border-left-color: white;
            position: absolute;
            top: 13px;
            right: -17px;
          }
        `}
`;

const StyledVoteDiv = styled("div")`
  //cursor: pointer;
  text-align: center;
  background: ${(props) => props.theme.palette.primary.main};
  border-radius: 10px 10px 10px 10px;
  box-shadow: grey 0px 7px 16px -4px;
  padding: 10px;
  color: ${(props) => props.theme.palette.primary.contrastText};
  &:hover {
    background: ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
  }
`;

const StyledReadedVoteDiv = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledDispTime = styled("div")`
  display: flex;
  align-items: flex-end;
`;

const StyledDispBukai = styled("div")`
  display: flex;
`;

function Message({
  conversation,
  lastmesasgeid,
  setOpenRead,
  setOpenEdit,
  setEditMessage,
  //setOpenGood,
  setOpenQuestion,
  setCurrentMessageid,
  //setInitQuestionid,
  setInitMessageid,
  //callback,
  //setIsReply,
  setOpenReply,
  setOpenReplyid,

  setAvatarOpen,
  setImgOpen,
  setVideoOpen,
  setInitHonbuid,
  setCallOpen,
  //setOpenCommon,
  handleMenuView,
  handleMenuView2,
  //setOpenAnpi,
  isScrollBottom,
  isBukai,
  searchHpName,
  // setDocurl,
  setBotImgOpen,
  setBotUrl,
}) {
  /*   const [open, setOpen] = React.useState(false);
  const [imgopen, setImgOpen] = React.useState(false);
  const [videoopen, setVideoOpen] = React.useState(false); */

  const handleClickOpen = () => {
    setInitHonbuid(conversation.honbuid);
    setAvatarOpen(true);
  };
  const handleVideoClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setVideoOpen(true);
  };

  const handleImgClickOpen = () => {
    setInitMessageid(conversation.messageid);
    setImgOpen(true);
  };
  const handleBotImgClickOpen = () => {
    setBotUrl(conversation.message);
    setBotImgOpen(true);
  };

  //const [localgooded, setLocalgooded] = React.useState(conversation.gooded);
  //const [showStar, setShowStar] = React.useState(false);
  const sendGoodEmoji = (emojitype) => {
    // if (localgooded == "1") {
    //   setLocalgooded("0");
    // } else {
    //   setLocalgooded("1");
    // }
    hubConnection.invoke(
      "SendGoodEmoji",
      conversation.roomid,
      conversation.messageid,
      localStorage.getItem("honbuid"),
      emojitype
    );
  };

  const handlePspDicom = (link) => {
    window.open(link, "_blank");
  };
  const handleQuestion = () => {
    //setInitQuestionid(conversation.questionid);
    //setInitMessageid(conversation.messageid);
    //setCurrentMessageid(conversation.messageid);
    setOpenQuestion({ open: true, messageid: conversation.messageid, questionid: conversation.questionid });
  };

  // const handleAnpi = () => {
  //   setOpenAnpi(true);
  // };

  const preventDefault = (event) => {
    event.preventDefault();
    setCurrentMessageid(conversation.messageid);
    setOpenRead({ open: true, kbn: "message" });
  };
  // const preventDefaultAnpi = (event) => {
  //   event.preventDefault();
  //   setCurrentMessageid(conversation.messageid);
  //   setOpenRead({ open: true, kbn: "anpi" });
  // };
  const preventDefaultQuestion = (event) => {
    event.preventDefault();
    setCurrentMessageid(conversation.messageid);
    setOpenRead({ open: true, kbn: "question" });
  };

  const preventEditDefault = (event) => {
    event.preventDefault();
    setCurrentMessageid(conversation.messageid);
    setOpenEdit(true);
  };

  /*const showgoodcnt = () => {
    setCurrentMessageid(conversation.messageid);
    setOpenGood(true);
  };*/

  const me = localStorage.getItem("honbuid");
  const sent = conversation.honbuid === me;
  ////console.log("Message sent", sent);
  ////console.log("conversation", conversation);

  const { hubConnection, dispatch } = useContext(Store);

  useEffect(() => {
    if (isScrollBottom) {
      const proc = async () => {
        /* 既読の送信が早すぎると空ぶるかもしれないので、ここで0.5秒待ち */
        //await sleep(500);
        hubConnection.invoke("ReadMessage", conversation.roomid, me);
      };

      if (
        conversation.unreaded &&
        conversation.unreaded === "1" &&
        conversation.honbuid !== me &&
        conversation.messageid === lastmesasgeid
      ) {
        dispatch({ type: "LOCAL_READMESSAGE", payload: {} });
        proc();
      }
    }
  }, [conversation]);

  //const sleep = (msec) => new Promise((resolve) => setTimeout(resolve, msec));

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handleMenuView = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  // };

  // const [openModal, setOpenModal] = React.useState(false);
  // const handleModalClose = () => {
  //   setOpenModal(false);
  // };

  const strsplit = (str, num) => {
    var sp = str.split(":");
    if (sp.length == 0) {
      return "";
    }
    if (sp.length >= num) {
      return sp[num];
    }
    return "";
  };

  return (
    <>
      {conversation.messagetype === "information" ? (
        <div className="message-information-wrapper">
          <div className="message-information-wrapper-child">{conversation.message}</div>
        </div>
      ) : conversation.messagetype === "pspdicom" ? (
        <StyledReadedVoteDiv>
          <div
            className="message-information-wrapper"
            onClick={() => handlePspDicom(conversation.message.split("@")[1])}
          >
            <StyledVoteDiv>
              <div>
                {conversation.isavatar == "1" ? (
                  <CheckBoxTwoToneIcon fontSize="large"></CheckBoxTwoToneIcon>
                ) : (
                  <QuestionAnswerTwoToneIcon fontSize="large"></QuestionAnswerTwoToneIcon>
                )}
                {/* <QuestionAnswerTwoToneIcon fontSize="large"></QuestionAnswerTwoToneIcon> */}
              </div>
              <div>{conversation.message.split("@")[0] + " の画像が投稿されました。"}</div>
              <div>{"撮影日：" + conversation.message.split("@")[2]}</div>
            </StyledVoteDiv>
          </div>
          <StyledDispTime>
            <Link href="#" onClick={preventDefaultQuestion}>
              {conversation.readedcnt > 0 && `既読${conversation.readedcnt}`}
            </Link>
            <div className="ml-10">{conversation.time}</div>
          </StyledDispTime>
        </StyledReadedVoteDiv>
      ) : conversation.messagetype === "question" ? (
        <StyledReadedVoteDiv>
          <div className="message-information-wrapper" onClick={handleQuestion}>
            <StyledVoteDiv>
              <div>
                {conversation.isavatar == "1" ? (
                  <CheckBoxTwoToneIcon fontSize="large"></CheckBoxTwoToneIcon>
                ) : (
                  <QuestionAnswerTwoToneIcon fontSize="large"></QuestionAnswerTwoToneIcon>
                )}
                {/* <QuestionAnswerTwoToneIcon fontSize="large"></QuestionAnswerTwoToneIcon> */}
              </div>
              <div>{conversation.message}</div>
              <div style={{ fontSize: "80%", marginTop: "5px" }}>
                {conversation.isavatar == "1" ? "回答済" : "(クリックをしてアンケートを開始)"}
              </div>
              <div style={{ fontSize: "80%" }}>{conversation.name} が作成</div>
            </StyledVoteDiv>
          </div>
          <StyledDispTime>
            <Link href="#" onClick={preventDefaultQuestion}>
              {conversation.readedcnt > 0 && `既読${conversation.readedcnt}`}
            </Link>
            <div className="ml-10">{conversation.time}</div>
          </StyledDispTime>
        </StyledReadedVoteDiv>
      ) : conversation.messagetype === "quake" ? (
        <StyledReadedVoteDiv>
          <StyledTableDiv
            onClick={() => {
              // if (conversation.message.split("@")) {
              //   var xmlid = conversation.message.split("@")[0];
              //   window.open(
              //     `https://www.data.jma.go.jp/multi/quake/quake_detail.html?eventID=${strsplit(xmlid, 1)}&lang=jp`,
              //     "_blank"
              //   );
              // } else {
              //   window.open("https://www.data.jma.go.jp/multi/quake/index.html?lang=jp", "_blank");
              // }

              window.open("https://www.data.jma.go.jp/multi/quake/index.html?lang=jp", "_blank");
            }}
          >
            <StyledTable>
              <tbody>
                <tr>
                  <StyledTdHeader colSpan="2">地震情報</StyledTdHeader>
                </tr>
                {conversation.message.split("@") &&
                  conversation.message.split("@").map((e, index) => {
                    if (index == 0) {
                      return null;
                    } else {
                      return (
                        <tr key={index}>
                          <StyledTdRow1>{strsplit(e, 0)}</StyledTdRow1>
                          <StyledTdRow2>{strsplit(e, 1)}</StyledTdRow2>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </StyledTable>
          </StyledTableDiv>
        </StyledReadedVoteDiv>
      ) : conversation.messagetype === "anpi" ? (
        <div></div>
      ) : conversation.messagetype === "call" ? (
        <div className="message-information-wrapper" onClick={() => setCallOpen(true)}>
          <StyledVoteDiv>
            <div>
              <PhoneIcon fontSize="large"></PhoneIcon>
            </div>
            <div>{conversation.message}</div>
            <div>{conversation.time}</div>
          </StyledVoteDiv>
        </div>
      ) : (
        <div className={`message-in-avatar-${sent ? "sent" : "received"}`}>
          {conversation.messageid == "0" && <div>送信中...</div>}

          {!sent && (
            <Tooltip title={conversation.name} aria-label={conversation.name}>
              <img
                src={
                  STATIC_URL +
                  `/downloadAvatar?honbuid=${conversation.honbuid}&fileno=${conversation.fileno}&size=small`
                }
                width="40"
                height="40"
                alt=""
                className="chat-avatar-image"
                onClick={() => handleClickOpen()}
              />
            </Tooltip>
          )}
          <div className={`message-options-wrapper-${sent ? "sent" : "received"}`}>
            <StyledDispBukai>
              {!sent && <div>{conversation.name}</div>}
              {!sent && isBukai == "1" ? <div> ({searchHpName(conversation.honbuid)})</div> : ""}
            </StyledDispBukai>
            {conversation.messagetype === "stamp" ? (
              <StampMessage conversation={conversation} isReplySource={false}></StampMessage>
            ) : (
              <StyledLeftRight leftmessage={!sent} className={`message`}>
                {conversation.messagetype === "replymessage" ? (
                  <ReplyMessage
                    conversation={conversation}
                    setOpenReplyid={setOpenReplyid}
                    setOpenReply={setOpenReply}
                    sent={sent}
                  ></ReplyMessage>
                ) : conversation.messagetype === "message" ? (
                  <TextMessage conversation={conversation} type="message"></TextMessage>
                ) : conversation.messagetype === "video" ? (
                  <VideoMessage
                    conversation={conversation}
                    handleVideoClickOpen={handleVideoClickOpen}
                    isReplySource={false}
                  ></VideoMessage>
                ) : conversation.messagetype === "photo" ? (
                  <PhotoMessage
                    conversation={conversation}
                    handleImgClickOpen={handleImgClickOpen}
                    isReplySource={false}
                  ></PhotoMessage>
                ) : conversation.messagetype === "botphoto" ? (
                  <BotPhotoMessage
                    url={conversation.message}
                    handleImgClickOpen={handleBotImgClickOpen}
                  ></BotPhotoMessage>
                ) : conversation.messagetype === "file" ? (
                  <FileMessage
                    conversation={conversation}
                    sent={sent}
                    isReplySource={false}
                    // setDocurl={setDocurl}
                  ></FileMessage>
                ) : (
                  <div>{conversation.message}</div>
                )}
              </StyledLeftRight>
            )}

            <div className={`message-options-time-${sent ? "sent" : "received"}`}>
              {/* <img src={ojigiman} height="30" alt="アイコン" /> */}

              <div className="mr-10">
                <Link href="#" onClick={preventDefault}>
                  {conversation.readedcnt > 0 && `既読${conversation.readedcnt}`}
                </Link>
              </div>

              <div>{conversation.time}</div>

              {conversation.edited == "1" && (
                <div className="mlr-5">
                  {sent ? (
                    <Link href="#" onClick={preventEditDefault}>
                      修正あり
                    </Link>
                  ) : (
                    <div>修正あり</div>
                  )}
                </div>
              )}
              <div>
                {/* <IconButton color="primary" aria-label="delete message" > */}

                {sent ? (
                  <StyledExpandMoreIcon
                    //className={classes.pointicon}
                    onClick={(e) => {
                      setInitMessageid(conversation.messageid);
                      setEditMessage({ messagetype: conversation.messagetype, message: conversation.message });
                      handleMenuView(e);
                    }}
                  ></StyledExpandMoreIcon>
                ) : (
                  <StyledExpandMoreIcon
                    //className={classes.pointicon}
                    onClick={(e) => {
                      setInitMessageid(conversation.messageid);
                      setEditMessage({ messagetype: conversation.messagetype, message: conversation.message });
                      handleMenuView2(e);
                    }}
                  ></StyledExpandMoreIcon>
                )}
              </div>

              <div
                className={`${conversation.emojicnt1 == "0" && conversation.goodcnt == "0" ? "" : "message-goodemoji"}${
                  conversation.emojiselected1 == "1" ? "-selected" : ""
                } `}
                onClick={() => sendGoodEmoji("emoji1")}
              >
                <div className={"parrent-star-div"}>
                  {conversation.emojicnt1 == "0" && conversation.goodcnt == "0" ? null : GOOD_EMOJI[0]}
                </div>
                {conversation.emojicnt1 != "0" || conversation.goodcnt != "0" ? (
                  <div>{Number(conversation.emojicnt1) + Number(conversation.goodcnt)}</div>
                ) : (
                  <div></div>
                )}
              </div>
              <div
                className={`${conversation.emojicnt2 == "0" ? "" : "message-goodemoji"}${
                  conversation.emojiselected2 == "1" ? "-selected" : ""
                } `}
                onClick={() => sendGoodEmoji("emoji2")}
              >
                <div className={"parrent-star-div"}>{conversation.emojicnt2 == "0" ? null : GOOD_EMOJI[1]}</div>
                {conversation.emojicnt2 != "0" && <div>{conversation.emojicnt2}</div>}
              </div>
              <div
                className={`${conversation.emojicnt3 == "0" ? "" : "message-goodemoji"}${
                  conversation.emojiselected3 == "1" ? "-selected" : ""
                } `}
                onClick={() => sendGoodEmoji("emoji3")}
              >
                <div className={"parrent-star-div"}>{conversation.emojicnt3 == "0" ? null : GOOD_EMOJI[2]}</div>
                {conversation.emojicnt3 != "0" && <div>{conversation.emojicnt3}</div>}
              </div>
              <div
                className={`${conversation.emojicnt4 == "0" ? "" : "message-goodemoji"}${
                  conversation.emojiselected4 == "1" ? "-selected" : ""
                } `}
                onClick={() => sendGoodEmoji("emoji4")}
              >
                <div className={"parrent-star-div"}>{conversation.emojicnt4 == "0" ? null : GOOD_EMOJI[3]}</div>
                {conversation.emojicnt4 != "0" && <div>{conversation.emojicnt4}</div>}
              </div>
              <div
                className={`${conversation.emojicnt5 == "0" ? "" : "message-goodemoji"}${
                  conversation.emojiselected5 == "1" ? "-selected" : ""
                } `}
                onClick={() => sendGoodEmoji("emoji5")}
              >
                <div className={"ojigiman"}>
                  {conversation.emojicnt5 == "0" ? null : <img src={ojigiman} height="16" alt="アイコン" />}
                  {conversation.emojicnt5 != "0" && <div>{conversation.emojicnt5}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Message;
