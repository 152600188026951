/* eslint-disable react/prop-types */
import "./ShowUserInfo.css";
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import ShowAnpiFilterInputDetail from "../Common/ShowAnpiFilterInputDetail";
import DialogContentText from "@mui/material/DialogContentText";

const StyledDialogTitle = styled(DialogTitle)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
  padding: 5px 10px;
`;
const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
`;
const StyledButton = styled(Button)`
  color: ${(props) => props.theme.palette.primary.contrastText};
  background-color: ${(props) => props.theme.palette.primary.main};
`;

const StyledDialogContent = styled(DialogContent)`
  height: 70vh;
  /* padding: 0px; */
`;
function ShowAnpiFilterInput({ open, handleClose, callBackProc, VoteLists }) {
  const handleCancel = async () => {
    const resulutList = localList.map((e) => {
      return {
        ...e,
        list: e.list.map((n) => {
          return { ...n, checked: "true" };
        }),
      };
    });
    // console.log(resulutList);
    setLocalList(resulutList);
    callBackProc(resulutList);
    handleClose();
  };

  const [localList, setLocalList] = useState([]);
  useEffect(() => {
    if (open) {
      setLocalList(VoteLists);
    }
  }, [open]);

  const inputSelectedUnit = (seq, selectedunit) => {
    //ここでさらにShowAnpiList.jsに返す値を入れる
    //各設問(seq)の回答(no)
    setLocalList(
      localList.map((e) => {
        if (e.seq === seq) {
          return {
            ...e,
            list: e.list.map((n) => {
              var o = 0;
              selectedunit.map((m) => {
                if (n.no === m.value) {
                  o++;
                }
              });
              if (o == 0) {
                return { ...n, checked: "false" };
              } else {
                return { ...n, checked: "true" };
              }
            }),
          };
        }
        return e;
      })
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"}>
      <StyledDialogTitle id="max-width-dialog-title">
        <div className="show-member-search">
          <div className="show-member-search">絞り込み検索</div>
          <div className="question-title-actions">
            <StyledButton onClick={handleClose} variant="outlined">
              閉じる (Esc)
            </StyledButton>
          </div>
        </div>
      </StyledDialogTitle>

      <StyledDialogContent>
        <div>
          {localList.map((e) => {
            return (
              <div key={e.seq}>
                <DialogContentText>{e.title}</DialogContentText>
                <ShowAnpiFilterInputDetail key={e.field} initvalue={e} inputSelectedUnit={inputSelectedUnit} />
              </div>
            );
          })}
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          //   style={{ margin: "0 auto" }}
          variant="contained"
          color="primary"
          onClick={() => {
            callBackProc(localList);
          }}
          startIcon={<SearchIcon />}
        >
          絞り込み検索
        </Button>
        <Button onClick={handleCancel} variant="outlined" color="primary">
          検索条件解除
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
}

export default ShowAnpiFilterInput;
